
.background-content {
    transition: opacity 0.3s ease-in-out; /* Smooth transition for content */
  }
  
  .background-content.loaded {
    opacity: 1; /* Fade in effect */
  }
  
  .hollow-text {
    /* font-size: 4rem; */
    font-weight: bold;
    color: red;
    -webkit-text-stroke: 1px #fff;
    -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
  }
  
  .background-content:not(.loaded) {
    opacity: 0; /* Dimmed effect while loading */
    pointer-events: none; /* Prevent interaction during loading */
  }
  
  .mobile {
    display: block !important;
  }
  @media all and (max-width: 991px) {
    .mobile {
      display: none !important;
    }
  }
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: calc(100% - var(--bs-modal-margin) * 2);
      margin-right: auto;
      margin-left: auto;
    }
  }
  
  .project-items {
    text-align: center;
    transition: transform 0.5s ease;
  }
  
  .project-items img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    transition: transform 0.5s ease;
  }
  
  .slick-center .project-items {
    /* transform: scale(1.1);  */
  }
  
  .slick-slide {
    opacity: 1;
    transition: opacity 0.5s ease;
  }
  
  .slick-center {
    opacity: 1; /* Full opacity for the center slide */
  }
  /* Example CSS to add hover effect */
  .scroll-to-top-btn {
    transition: background-color 0.3s ease;
  }
  
  .scroll-to-top-btn:hover {
    background-color: #0056b3; /* Change to a darker shade on hover */
  }
  
  .testimonial-card-items {
    text-align: center;
    padding: 0px 0px;
    border-radius: 8px;
    background-color: transparent;
  
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
  .slick-list {
    margin: 0 -15px;
  }
  .slick-slide > div {
    padding: 0 15px;
  }
  
  .client-img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    margin: 0 15px auto 15px;
  }
  
  .client-info h3 {
    font-weight: bold;
    margin-top: 10px;
  }
  
  .icon {
    font-size: 24px;
    color: #333;
  }
  
  .testimonial-section .slick-dots {
    bottom: -40px;
  }
  
  @keyframes marquee {
    from {
        transform: translateX(-50%);
    }
    to {
        transform: translateX(0);
    }
    // from {
    //   transform: translateX(100%);
    // }
    // to {
    //   transform: translateX(-100%);
    // }
  }
  
  .animate-marquee {
    animation: marquee 15s linear infinite;
  }
  
  .animate-pause {
    animation-play-state: paused;
  }
  
  /* Prefixes for cross-browser support */
  @-webkit-keyframes marquee {
    from {
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
    to {
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
  }
  
  .animate-marquee {
    position:absolute;
    -webkit-animation: marquee 15s linear infinite;
    animation: marquee 10s linear infinite;
  }
  
  .animate-marquee-slow {
    position:absolute;
    -webkit-animation: marquee 15s linear infinite; /* Slow speed */
    animation: marquee 15s linear infinite;
  }
  
  .animate-pause {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
  
  @keyframes fadeInUp {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0%);
      opacity: 1;
    }
  }
  
  .fadeInUp {
    animation: 1s fadeInUp;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  @keyframes fadeImage {
    0% {
      transform: translateY(20%);
      opacity: 0;
    }
    100% {
      transform: translateY(0%);
      opacity: 1;
    }
  }
  
  .fadeImage {
    animation: 1.5s fadeImage;
  }
  

  
.card.custom-card.card-style-2:hover {
  box-shadow: 0 3px 10px 0px var(--black-1);
}
.card-style-2 {
  overflow: hidden;
}
.card-style-2 .img-box-2 {
  transition: transform ease 1.5s;
  display: flex;
  justify-content: center;
}
.card-style-2 .card-img-top {
  position: relative;
}
.card-style-2 .btn-style-1 {
  position: absolute;
  inset-inline-end: 0px;
  bottom: 0px;
  border-radius: 0.625rem 0 0rem;
  box-shadow: none !important;
}
.card-style-2:hover {
  border-color: var(--primary02);
}
.card-style-2:hover .btns-container-1 {
  display: inline-flex;
  width: 100%;
  background-color: var(--custom-white);
  padding: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-style-2:hover .badge.top-right-badge .badge-text {
  max-width: 100px;
  margin-inline-start: 3px;
}
.card-style-2:hover .scale-img {
  scale: 1.1;
  transition: all 0.3s ease-in-out;
}
.card-style-2 .btns-container-1 {
  position: absolute;
  inset-inline-start: 50%;
  bottom: -9%;
  transform: translate(-50%, -50%);
  display: none;
  z-index: 2;
}