/* ===== button.btn-group CSS ===== */

.btn-group {
  button {
    color: var(--color-merkos-primary);
    border-color: var(--color-merkos-primary);
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;

    &:hover {
      color: var(--color-merkos-primary);
      background-color: $color-1;
      border-color: var(--color-merkos-primary);
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      font-weight: 400;
    }

    &.active {
      color: var(--color-merkos-primary);
      background-color: $color-1;
      border-color: var(--color-merkos-primary);
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      font-weight: 400;

      &:focus {
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.btn-group-joined {
  gap: 0 !important;
  button:not(:last-of-type) {
    border-start-end-radius: 0;
    border-end-end-radius: 0;
  }
  button:not(:first-of-type) {
    border-start-start-radius: 0;
    border-end-start-radius: 0;
  }
}
