/*
Theme Name: Nexava
Author: Cmshaper
Author URI:https://themeforest.net/user/cmshaper/portfolio
Description: Nexava - Roofing Services Html Template
Service Html Template 
Version: 1.0.0
*/
/*CSS Table Of Content Ends Here*/
@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Outfit:wght@100..900&display=swap");


.hero-padding {
  position: relative;
  padding: 100px 0 190px;
}

@media (max-width: 1199px) {
  .hero-padding {
    padding: 240px 0 140px;
    text-align: center;
  }
}

@media (max-width: 991px) {
  .hero-padding {
    padding: 210px 0 110px;
  }
}

@media (max-width: 767px) {
  .hero-padding {
    padding: 170px 0 90px;
  }
}



.intheme {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Jost", sans-serif;
    margin: 0px;
    padding: 0;

    transition: all 0.4s ease-in-out;
  }

  h1 {
    font-size: 80px;
    font-weight: 600;
    line-height: 113%;
  }
}

:root {
  --body: #fff;
  --black: #000;
  --white: #fff;
  --theme: #2bc7f7;
  --theme2: var(--color-merkos-primary);
  --header: #141a18;
  --text: #74787c;
  --border: #dedede;
  --border-2: #d4dced;
  --bg: #102039;
  --box-shadow: 0px 4px 30px 0px rgba(233, 212, 212, 0.25);
}

.theme-btn {
  display: inline-block;
  vertical-align: middle;
  -webkit-appearance: none;
  border: none;
  outline: none !important;
  background-color: var(--color-merkos-primary);
  color: var(--white);
  font-size: 16px;
  font-weight: 500;
  padding: 22px 40px;
  transition: 0.5s ease-in-out;
  text-transform: capitalize;
  position: relative;
  z-index: 2;
  overflow: hidden;
  text-align: center;
  font-family: "DM Sans", sans-serif;
  line-height: 1;
}

.theme-btn::after {
  content: "";
  background-color: var(--header);
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  clip-path: circle(0% at 50% 50%);
  transition: all cubic-bezier(0, 0.96, 0.58, 1.1) 0.8s;
  z-index: -1;
}

.theme-btn i {
  margin-left: 10px;
}

.theme-btn.header-bg {
  background-color: var(--header);
}

.theme-btn.header-bg::after {
  background-color: var(--color-merkos-primary);
}

.theme-btn.header-bg:hover {
  background-color: var(--color-merkos-primary);
}

.theme-btn:hover {
  background-color: var(--header);
  color: var(--white);
}

.theme-btn:hover::after {
  clip-path: circle(100% at 50% 50%);
  transition: all cubic-bezier(0, 0.96, 0.58, 1.1) 4s;
}

@media (max-width: 767px) {
  .theme-btn {
    padding: 20px 32px;
  }
}

@media (max-width: 575px) {
  .theme-btn {
    padding: 18px 30px;
    font-size: 14px;
  }
}

.link-btn {
  font-family: "DM Sans";
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
  color: var(--text);
  display: inline-block;
}

.link-btn i {
  color: var(--header);
  padding-left: 7px;
  transition: all 0.4s ease-in-out;
}

.link-btn:hover {
  color: var(--color-merkos-primary);
}

.link-btn:hover i {
  color: var(--color-merkos-primary);
}

/* --------------------------------------------
    Template Default Fonts & Fonts Styles
 ---------------------------------------------- */
body {
  font-family: "Kumbh Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  // color: var(--text);
  // background-color: var(--white);
  padding: 0;
  margin: 0;
}

.intheme {
  line-height: 28px;
  // overflow-x: hidden;
  
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.intheme button {
  border: none;
  background-color: transparent;
  padding: 0;
}

.intheme input:focus {
  color: var(--white);
  outline: none;
}

.intheme input {
  color: var(--white);
}



.theme-header {
  color: var(--header);
  text-transform: capitalize;
}



h2 {
  font-size: 36px;
  line-height: 128%;
  font-weight: 600;
}

@media (max-width: 1199px) {
  h2 {
    font-size: 42px;
  }
}

@media (max-width: 767px) {
  h2 {
    font-size: 36px;
  }
}

@media (max-width: 575px) {
  h2 {
    font-size: 28px;
  }
}

h3 {
  font-size: 24px;
  font-weight: 500;
}

@media (max-width: 575px) {
  h3 {
    font-size: 20px;
  }
}

h4 {
  font-size: 20px;
  font-weight: 700;
  // color:var(--theme)
}

h5 {
  font-size: 18px;
  font-weight: 700;
}

h6 {
  font-size: 16px;
  font-weight: 700;
}

a {
  text-decoration: none;
  outline: none !important;
  cursor: pointer;
  color: var(--header);
  transition: all 0.4s ease-in-out;
}

p {
  margin: 0px;
  transition: all 0.4s ease-in-out;
}

span {
  margin: 0px;
  transition: all 0.4s ease-in-out;
}

.header-logo img {
  width: 120px;
  height: auto;
}

.about-wrapper {
  border: 1px solid var(--border);
  padding: 42px;
  position: relative;
  z-index: 9;
}

@media (max-width: 1899px) {
  .about-wrapper {
    margin-top: -50px;
  }
}

@media (max-width: 1600px) {
  .about-wrapper {
    margin-top: -60px;
  }
}

@media (max-width: 1399px) {
  .about-wrapper {
    margin-top: -70px;
  }
}

@media (max-width: 1199px) {
  .about-wrapper {
    margin-top: -65px;
  }
}

@media (max-width: 991px) {
  .about-wrapper {
    margin-top: -55px;
  }
}

.about-wrapper::before {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  height: 100%;
  content: "";
  border-right: 1px solid var(--border);
  left: 49%;
}

@media (max-width: 1399px) {
  .about-wrapper::before {
    display: none;
  }
}

.about-wrapper .about-bg-shape {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.about-wrapper .about-image {
  max-width: 564px;
  position: relative;
}

.about-wrapper .about-image img {
  width: 100%;
  height: 100%;
}

.about-wrapper .about-image .about-image-2 {
  position: absolute;
  top: 0;
  right: 0;
  border-left: 20px solid var(--white);
  border-bottom: 20px solid var(--white);
}

@media (max-width: 767px) {
  .about-wrapper .about-image .about-image-2 {
    max-width: 230px;
  }
}

@media (max-width: 575px) {
  .about-wrapper .about-image .about-image-2 {
    max-width: 150px;
  }
}

.about-wrapper .about-content {
  margin-left: 30px;
}

@media (max-width: 1199px) {
  .about-wrapper .about-content {
    margin-left: 0;
  }
}

.about-wrapper .about-content p {
  max-width: 564px;
}

.about-wrapper .about-content .about-list {
  margin-top: 30px;
  margin-bottom: 40px;
}

.about-wrapper .about-content .about-list li {
  font-size: 16px;
  font-weight: 600;
  color: var(--header);
  display: flex;
  align-items: center;
  gap: 15px;
}

.about-wrapper .about-content .about-list li:not(:last-child) {
  margin-bottom: 20px;
}

.about-wrapper .about-content .about-list li i {
  color: var(--color-merkos-primary);
  font-size: 20px;
}

.about-section {
  position: relative;
}

.about-section .about-shape {
  position: absolute;
  top: 14%;
  left: 35px;
}

.about-section .about-right-shape {
  position: absolute;
  top: 0;
  right: 0;
}

.about-wrapper-2 {
  margin-bottom: 102px;
}

@media (max-width: 1199px) {
  .about-wrapper-2 {
    margin-bottom: 60px;
  }
}

.about-wrapper-2 .about-image {
  position: relative;
  padding-left: 40px;
  z-index: 2;
}

@media (max-width: 1199px) {
  .about-wrapper-2 .about-image {
    padding-left: 0;
  }

  .about-wrapper-2 .about-image img {
    width: 100%;
    height: 100%;
  }
}

.about-wrapper-2 .about-image .about-image-2 {
  border: 10px solid var(--white);
  position: absolute;
  bottom: 0;
  right: 75px;
}

@media (max-width: 1199px) {
  .about-wrapper-2 .about-image .about-image-2 {
    right: 0;
  }
}

@media (max-width: 470px) {
  .about-wrapper-2 .about-image .about-image-2 {
    width: 200px;
  }
}

.about-wrapper-2 .about-image .about-image-2 .video-btn {
  display: inline-block;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border-radius: 50%;
  background-color: var(--color-merkos-primary);
  color: var(--white);
  font-size: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 575px) {
  .about-wrapper-2 .about-image .about-image-2 .video-btn {
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: 18px;
  }
}

.about-wrapper-2 .about-image .about-image-2 .video-btn.ripple::before,
.about-wrapper-2 .about-image .about-image-2 .video-btn.ripple::after {
  width: 100px;
  height: 100px;
  box-shadow: 0 0 0 0 rgba(96, 172, 69, 0.5);
}

@media (max-width: 575px) {

  .about-wrapper-2 .about-image .about-image-2 .video-btn.ripple::before,
  .about-wrapper-2 .about-image .about-image-2 .video-btn.ripple::after {
    width: 70px;
    height: 70px;
  }
}

.about-wrapper-2 .about-image::before {
  position: absolute;
  top: 40px;
  left: 0;
  width: 450px;
  height: 541px;
  content: "";
  border: 10px solid var(--color-merkos-primary);
  z-index: -1;
}

@media (max-width: 1199px) {
  .about-wrapper-2 .about-image::before {
    display: none;
  }
}

.about-wrapper-2 .about-content p {
  max-width: 570px;
}

.about-wrapper-2 .about-content .about-list {
  margin-top: 30px;
  margin-bottom: 40px;
}

.about-wrapper-2 .about-content .about-list li {
  font-size: 16px;
  font-weight: 600;
  color: var(--white);
  display: flex;
  align-items: center;
  gap: 15px;
}

.about-wrapper-2 .about-content .about-list li:not(:last-child) {
  margin-bottom: 20px;
}

.about-wrapper-2 .about-content .about-list li i {
  color: var(--color-merkos-primary);
  font-size: 20px;
}

.about-wrapper-3 {
  margin-bottom: 45px;
}

@media (max-width: 991px) {
  .about-wrapper-3 {
    margin-bottom: 0;
  }
}

.about-wrapper-3 .about-image {
  position: relative;
  z-index: 2;
}

@media (max-width: 1199px) {
  .about-wrapper-3 .about-image {
    max-width: 800px;
  }

  .about-wrapper-3 .about-image img {
    width: 100%;
    height: 100%;
  }
}

.about-wrapper-3 .about-image .about-image-2 {
  position: absolute;
  bottom: -44px;
  right: 10px;
}

@media (max-width: 991px) {
  .about-wrapper-3 .about-image .about-image-2 {
    bottom: 0;
    right: 0;
  }
}

@media (max-width: 767px) {
  .about-wrapper-3 .about-image .about-image-2 {
    max-width: 300px;
  }
}

@media (max-width: 575px) {
  .about-wrapper-3 .about-image .about-image-2 {
    max-width: 200px;
  }
}

.about-wrapper-3 .about-content {
  padding-left: 100px;
}

@media (max-width: 1199px) {
  .about-wrapper-3 .about-content {
    padding-left: 0;
  }
}

.about-wrapper-3 .about-content .date {
  margin-top: 25px;
}

.about-wrapper-3 .about-content .date li {
  font-size: 16px;
  font-weight: 600;
  color: var(--header);
}

.about-wrapper-3 .about-content .date li:not(:last-child) {
  margin-bottom: 10px;
}

.about-wrapper-4 {
  margin-bottom: 115px;
}

@media (max-width: 1399px) {
  .about-wrapper-4 {
    margin-bottom: 90px;
  }
}

@media (max-width: 1199px) {
  .about-wrapper-4 {
    margin-bottom: 0;
  }
}

.about-wrapper-4 .about-image {
  max-width: 555px;
  position: relative;
}

.about-wrapper-4 .about-image .dot-shape {
  position: absolute;
  left: -47px;
  bottom: -50px;
  z-index: -1;
}

@media (max-width: 1399px) {
  .about-wrapper-4 .about-image .dot-shape {
    display: none;
  }
}

.about-wrapper-4 .about-image img {
  width: 100%;
  height: 100%;
}

.about-wrapper-4 .about-image .icon-items {
  padding: 40px 30px;
  background-color: var(--theme2);
  position: absolute;
  bottom: -22%;
  right: -10%;
}

@media (max-width: 1399px) {
  .about-wrapper-4 .about-image .icon-items {
    right: 0;
  }
}

@media (max-width: 991px) {
  .about-wrapper-4 .about-image .icon-items {
    bottom: 0;
  }
}

@media (max-width: 575px) {
  .about-wrapper-4 .about-image .icon-items {
    max-width: 280px;
  }
}

@media (max-width: 470px) {
  .about-wrapper-4 .about-image .icon-items {
    padding: 25px;
  }
}

.about-wrapper-4 .about-image .icon-items::before {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 15px;
  height: 15px;
  background-color: var(--white);
  content: "";
}

.about-wrapper-4 .about-image .icon-items .icon {
  margin-bottom: 30px;
  filter: grayscale(100%) brightness(300%);
}

.about-wrapper-4 .about-image .icon-items .icon img {
  width: initial;
  height: initial;
  filter: grayscale(100%) brightness(300%);
}

@media (max-width: 470px) {
  .about-wrapper-4 .about-image .icon-items .icon {
    width: 40px;
    margin-bottom: 15px;
  }

  .about-wrapper-4 .about-image .icon-items .icon img {
    width: 100%;
    object-fit: cover;
  }
}

.about-wrapper-4 .about-image .icon-items h5 {
  color: var(--white);
}

@media (max-width: 470px) {
  .about-wrapper-4 .about-image .icon-items h5 {
    font-size: 14px;
  }
}

.about-wrapper-4 .about-content p {
  font-size: 18px;
}

.about-wrapper-4 .about-content .list-items {
  display: flex;
  align-items: center;
  gap: 25px;
  margin-top: 30px;
  border-bottom: 1px solid var(--border);
  padding-bottom: 30px;
  flex-wrap: wrap;
}

@media (max-width: 1199px) {
  .about-wrapper-4 .about-content .list-items {
    gap: 15px;
  }
}

.about-wrapper-4 .about-content .list-items ul li {
  font-size: 16px;
  color: var(--header);
  font-weight: 600;
}

.about-wrapper-4 .about-content .list-items ul li:not(:last-child) {
  margin-bottom: 10px;
}

.about-wrapper-4 .about-content .list-items ul li i {
  color: var(--color-merkos-primary);
  margin-right: 5px;
}

.about-wrapper-4 .about-content .about-author {
  display: flex;
  align-items: center;
  gap: 50px;
  margin-top: 40px;
}

@media (max-width: 1399px) {
  .about-wrapper-4 .about-content .about-author {
    gap: 40px;
  }
}

@media (max-width: 1199px) {
  .about-wrapper-4 .about-content .about-author {
    flex-wrap: wrap;
  }
}

.about-wrapper-4 .about-content .about-author .author-image {
  display: flex;
  align-items: center;
  gap: 20px;
  border-right: 1px solid var(--border);
  padding-right: 50px;
}

@-webkit-keyframes rippleOne {
  70% {
    -webkit-box-shadow: 0 0 0 40px rgba(244, 68, 56, 0);
    box-shadow: 0 0 0 40px rgba(244, 68, 56, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(244, 68, 56, 0);
    box-shadow: 0 0 0 0 rgba(244, 68, 56, 0);
  }
}

@keyframes rippleOne {
  70% {
    -webkit-box-shadow: 0 0 0 40px rgba(244, 68, 56, 0);
    box-shadow: 0 0 0 40px rgba(244, 68, 56, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(244, 68, 56, 0);
    box-shadow: 0 0 0 0 rgba(244, 68, 56, 0);
  }
}

@keyframes cir36 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rounded {
  50% {
    transform: rotate(15deg);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

@-webkit-keyframes spinner {
  to {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}

@keyframes spinner {
  to {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}

@-webkit-keyframes letters-loading {

  0%,
  75%,
  100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}

@keyframes letters-loading {

  0%,
  75%,
  100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}

@keyframes loaderspin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes tpswing {
  0% {
    -webkit-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
    transform: rotate(20deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes width {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@-webkit-keyframes width {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@-webkit-keyframes loaderspin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes loaderpulse {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }
}

@keyframes rounded {
  50% {
    transform: rotate(20deg);
  }
}

@keyframes cir36 {
  100% {
    transform: rotate(360deg);
  }
}

.float-bob-y {
  -webkit-animation-name: float-bob-y;
  animation-name: float-bob-y;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes float-bob-y {
  0% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  100% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}

@keyframes float-bob-y {
  0% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  100% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}

.float-bob-x {
  -webkit-animation-name: float-bob-x;
  animation-name: float-bob-x;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes float-bob-x {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(30px);
  }

  50% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }

  100% {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
}

@keyframes float-bob-x {
  0% {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }

  50% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }

  100% {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
}

@keyframes bounce-x {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.bounce-x {
  -webkit-animation: bounce-x 7s infinite linear;
  animation: bounce-x 7s infinite linear;
}

@keyframes criss-cross-left {
  0% {
    left: -20px;
  }

  50% {
    left: 50%;
    width: 20px;
    height: 20px;
  }

  100% {
    left: 50%;
    width: 375px;
    height: 375px;
  }
}

@keyframes criss-cross-right {
  0% {
    right: -20px;
  }

  50% {
    right: 50%;
    width: 20px;
    height: 20px;
  }

  100% {
    right: 50%;
    width: 375px;
    height: 375px;
  }
}

@keyframes rotated2 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(-360deg);
  }
}

@keyframes wave {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-25%);
  }

  100% {
    transform: translateX(-50%);
  }
}

@keyframes animate-positive {
  0% {
    width: 0;
  }
}

.contact-info-wrapper {
  background-color: var(--color-merkos-primary);
  padding: 60px 55px;
  position: relative;
  margin-top: -60px;
  overflow: hidden;
  position: relative;
}

@media (max-width: 575px) {
  .contact-info-wrapper {
    padding: 40px;
  }
}

.contact-info-wrapper .line-shape-2 {
  position: absolute;
  right: 0;
  top: 0;
}

.contact-info-wrapper .contact-img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

@media (max-width: 1399px) {
  .contact-info-wrapper .contact-img {
    display: none;
  }

  .contact-info-wrapper .contact-img img {
    width: 100%;
    height: 100%;
  }
}

.contact-info-wrapper .contact-info-items {
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
  z-index: 9;
}

@media (max-width: 1199px) {
  .contact-info-wrapper .contact-info-items {
    flex-wrap: wrap;
  }
}

.contact-info-wrapper .contact-info-items .icon {
  font-size: 36px;
  width: 90px;
  height: 90px;
  line-height: 90px;
  text-align: center;
  border-radius: 50%;
  color: var(--white);
  background-color: #202220;
}

.contact-info-wrapper .contact-info-items .content h3 {
  color: var(--white);
  font-weight: 500;
}

.contact-info-wrapper .contact-info-items .content h2 {
  font-size: 60px;
  font-weight: 500;
  -webkit-text-stroke: 1px var(--white);
  -webkit-text-fill-color: transparent;
}

@media (max-width: 575px) {
  .contact-info-wrapper .contact-info-items .content h2 {
    font-size: 26px;
  }
}

.contact-info-wrapper .contact-info-items .content h2 a {
  color: var(--white);
}

.contact-info-wrapper-22 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 1399px) {
  .contact-info-wrapper-22 {
    flex-wrap: wrap;
    gap: 30px;
  }
}

.contact-info-wrapper-22 .icon-items {
  display: flex;
  gap: 35px;
  position: relative;
}

.contact-info-wrapper-22 .icon-items::before {
  position: absolute;
  top: -130px;
  bottom: 0;
  content: "";
  right: -180px;
  width: 1px;
  height: 373px;
  background-color: #efe2e2;
}

@media (max-width: 1899px) {
  .contact-info-wrapper-22 .icon-items::before {
    display: none;
  }
}

.contact-info-wrapper-22 .icon-items .content h2 {
  font-size: 35px;
  font-weight: 500;
  margin-bottom: 10px;
}

.contact-info-wrapper-22 .icon-items .content h4 {
  font-size: 20px;
  font-weight: 400;
  font-family: "Kumbh Sans", sans-serif;
  text-transform: lowercase;
  color: var(--text);
}

.contact-info-wrapper-22 .icon-items .content h4 a {
  color: var(--text);
}

.contact-info-section-22 {
  border-bottom: 1px solid #efe2e2;
}

.contact-info-section-22 .container-fluid {
  padding: 0 135px;
}

@media (max-width: 1399px) {
  .contact-info-section-22 .container-fluid {
    padding: 0 40px;
  }
}

.contact-wrapper-11 .contact-content h2 {
  font-size: 35px;
  font-weight: 500;
  margin-bottom: 30px;
}

.contact-wrapper-11 .contact-form-area .form-clt input,
.contact-wrapper-11 .contact-form-area .form-clt textarea {
  width: 100%;
  outline: none;
  border: none;
  background-color: #f4f5f8;
  border: none;
  padding: 16px 20px;
  font-weight: 500;
  color: var(--text);
}

.contact-wrapper-11 .contact-form-area .form-clt input::placeholder,
.contact-wrapper-11 .contact-form-area .form-clt textarea::placeholder {
  color: var(--text);
}

.contact-wrapper-11 .contact-form-area .form-clt textarea {
  padding-bottom: 150px;
}

.contact-wrapper-12 .contact-image img {
  width: 100%;
  height: 100%;
}

.contact-wrapper-12 .contact-content {
  padding: 50px;
  background-color: var(--white);
  margin-left: -70px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

@media (max-width: 767px) {
  .contact-wrapper-12 .contact-content {
    padding: 50px 40px;
  }
}

@media (max-width: 575px) {
  .contact-wrapper-12 .contact-content {
    padding: 40px 30px;
  }
}

@media (max-width: 1199px) {
  .contact-wrapper-12 .contact-content {
    margin-left: 0;
  }
}

.contact-wrapper-12 .contact-content .form-clt input,
.contact-wrapper-12 .contact-content .form-clt textarea {
  line-height: 1;
  border: 1.5px solid var(--border);
  padding: 17px 20px;
  color: var(--text);
  outline: none;
  width: 100%;
  border-radius: 5px;
}

.contact-wrapper-12 .contact-content .form-clt textarea {
  padding: 17px 20px 125px;
}

@media (max-width: 1199px) {
  .contact-wrapper-12.style-padding-minus {
    margin-bottom: -50px;
  }
}

.contact-section-12 {
  position: relative;
  z-index: 9;
}

.contact-section-12 .contact-image-2 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

@media (max-width: 1199px) {
  .contact-section-12 .contact-image-2 {
    position: static;
    margin-bottom: 50px;
  }
}

.contact-section-12 .contact-image-2 img {
  width: 100%;
  height: 100%;
}

.cta-contact-wrapper {
  background-color: var(--color-merkos-primary);
  padding: 44px 63px;
}

@media (max-width: 767px) {
  .cta-contact-wrapper {
    padding: 40px 30px;
  }
}

.cta-contact-wrapper .cta-contact-content h3 {
  -webkit-text-stroke: 1px var(--white);
  -webkit-text-fill-color: transparent;
  font-size: 60px;
  font-weight: 500;
  line-height: 1;
}

@media (max-width: 1199px) {
  .cta-contact-wrapper .cta-contact-content h3 {
    font-size: 50px;
  }
}

@media (max-width: 767px) {
  .cta-contact-wrapper .cta-contact-content h3 {
    font-size: 40px;
  }
}

.cta-contact-wrapper .contact-into-items .form-clt input {
  outline: none;
  border: none;
  background-color: var(--white);
  color: var(--text);
  width: 100%;
  padding: 19px 30px;
  line-height: 1;
}

.cta-contact-wrapper .contact-into-items .form-clt input::placeholder {
  color: var(--text);
}

.cta-contact-wrapper .contact-into-items .theme-btn {
  background-color: var(--header);
  width: 100%;
}

.cta-contact-wrapper .contact-into-items .theme-btn::after {
  background-color: var(--white);
}

.cta-contact-wrapper .contact-into-items .theme-btn:hover {
  background-color: var(--white);
  color: var(--header);
}

.cta-contact-section {
  position: relative;
}

.cta-contact-section::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: var(--bg);
  top: 35%;
  z-index: -1;
}

.cta-contact-section.style-2::before {
  top: 50%;
}

.vedio-section {
  position: relative;
  background-attachment: fixed;
}

.vedio-section::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: linear-gradient(0deg,
      rgba(16, 32, 57, 0.6) 0%,
      rgba(16, 32, 57, 0.6) 100%);
}

.vedio-section .vedio-icon {
  padding: 120px 0px;
  text-align: center;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

@media (max-width: 767px) {
  .vedio-section .vedio-icon {
    padding: 80px 0;
  }
}

.vedio-section .vedio-icon .video-btn {
  width: 140px;
  height: 140px;
  line-height: 140px;
  text-align: center;
  border-radius: 50%;
  font-size: 16px;
  color: var(--white);
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  border: 1px solid rgba(255, 255, 255, 0.4);
}

.instagram-wrapper h3 {
  margin-bottom: 50px;
  margin-top: -9px;
}

.instagram-wrapper .instagram-banner-items .banner-image {
  position: relative;
}

@media (max-width: 767px) {
  .instagram-wrapper .instagram-banner-items .banner-image {
    height: 450px;
  }

  .instagram-wrapper .instagram-banner-items .banner-image img {
    object-fit: cover;
  }
}

.instagram-wrapper .instagram-banner-items .banner-image img {
  width: 100%;
  height: 100%;
}

@media (max-width: 575px) {
  .instagram-wrapper .instagram-banner-items .banner-image {
    height: 380px;
  }
}

.instagram-wrapper .instagram-banner-items .banner-image::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background: #385469;
  opacity: 0.6;
  transform: scale(1, 0);
  transition: transform 500ms ease;
  transform-origin: bottom center;
  z-index: 1;
}

.instagram-wrapper .instagram-banner-items .banner-image .icon {
  position: absolute;
  width: 68px;
  height: 68px;
  line-height: 68px;
  text-align: center;
  border-radius: 50%;
  background-color: var(--color-merkos-primary);
  font-size: 28px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  color: var(--white);
  opacity: 0;
  transition: all 0.4s ease-in-out;
}

.instagram-wrapper .instagram-banner-items:hover .banner-image::before {
  transform: scale(1, 1);
  transform-origin: top center;
}

.instagram-wrapper .instagram-banner-items:hover .banner-image .icon {
  opacity: 1;
}

.counter-section {
  margin-top: 90px;
}

.counter-section.style-2 {
  margin-top: 90px;
}

.faq-wrapper {
  margin-top: 90px;
}

@media (max-width: 1899px) {
  .faq-wrapper {
    margin-top: 0;
  }
}

.faq-wrapper .faq-image {
  height: 603px;
  position: relative;
}

@media (max-width: 767px) {
  .faq-wrapper .faq-image {
    height: 500px;
  }
}

@media (max-width: 575px) {
  .faq-wrapper .faq-image {
    height: 400px;
  }
}

.faq-wrapper .faq-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.faq-wrapper .faq-image .button-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 1199px) {
  .faq-wrapper .faq-image .button-text {
    display: none;
  }
}

.faq-wrapper .faq-image .button-text span {
  font-weight: 600;
  color: var(--white);
}

.faq-wrapper .faq-image .button-text .video-btn {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  text-align: center;
  line-height: 80px;
  background-color: var(--white);
  color: var(--color-merkos-primary);
  display: inline-block;
}

.faq-wrapper .faq-image .button-text .ripple::before,
.faq-wrapper .faq-image .button-text .ripple::after {
  width: 80px;
  height: 80px;
  box-shadow: 0 0 0 0 rgba(96, 172, 69, 0.5);
}

.faq-wrapper .faq-content {
  margin-left: 20px;
}

@media (max-width: 1199px) {
  .faq-wrapper .faq-content {
    margin-left: 0;
  }
}

.faq-wrapper .faq-content .accordion-item {
  border: none !important;
  background: #f7f7f7;
}

.faq-wrapper .faq-content .accordion-item .accordion-header .accordion-button {
  font-weight: 600;
  color: var(--header);
  letter-spacing: -0.2px;
  border: none !important;
  border-radius: 0;
  box-shadow: none;
  background: #f7f7f7;
  padding: 20px 30px 0;
  text-transform: capitalize;
  font-size: 18px;
}

@media (max-width: 1199px) {
  .faq-wrapper .faq-content .accordion-item .accordion-header .accordion-button {
    font-size: 20px;
  }
}

@media (max-width: 575px) {
  .faq-wrapper .faq-content .accordion-item .accordion-header .accordion-button {
    font-size: 18px;
    padding: 20px 20px 0;
  }
}

.faq-wrapper .faq-content .accordion-item .accordion-header .accordion-button span {
  margin-right: 20px;
  color: var(--color-merkos-primary);
}

.faq-wrapper .faq-content .accordion-item .accordion-header .accordion-button::after {
  content: "\f054";
  font-family: "Font Awesome 6 Pro";
  background: transparent;
  font-weight: 500;
  transition: all 0.3s ease-in-out !important;
  color: var(--header);
  font-size: 16px;
}

.faq-wrapper .faq-content .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
  content: "\f077";
  font-family: "Font Awesome 6 Pro";
  background: transparent;
  font-weight: 500;
  color: var(--color-merkos-primary);
}

.faq-wrapper .faq-content .accordion-item .accordion-header .accordion-button.collapsed {
  background: #f7f7f7;
  padding: 18px 30px;
  color: var(--header);
}

.faq-wrapper .faq-content .accordion-item .accordion-collapse .accordion-body {
  padding-left: 33px;
  padding-top: 15px;
  padding-right: 40px;
  color: var(--text);
  background: #f7f7f7;
  padding-bottom: 28px;
}

@media (max-width: 767px) {
  .faq-wrapper .faq-content .accordion-item .accordion-collapse .accordion-body {
    padding-left: 60px;
  }
}

@media (max-width: 575px) {
  .faq-wrapper .faq-content .accordion-item .accordion-collapse .accordion-body {
    padding-left: 50px;
    padding-bottom: 20px;
  }
}

.faq-wrapper .faq-content.style-2 {
  margin-left: 0;
}

.faq-wrapper .faq-image-2 {
  position: relative;
}

.faq-wrapper .faq-image-2 .faq-img {
  max-width: 480px;
  position: relative;
}

.faq-wrapper .faq-image-2 .faq-img .video-btn {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  text-align: center;
  line-height: 80px;
  background-color: var(--white);
  color: var(--color-merkos-primary);
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}

.faq-wrapper .faq-image-2 .faq-img .ripple::before,
.faq-wrapper .faq-image-2 .faq-img .ripple::after {
  width: 80px;
  height: 80px;
  box-shadow: 0 0 0 0 rgba(112, 0, 254, 0.5);
}

.faq-wrapper .faq-image-2 .faq-img img {
  width: 100%;
  height: 100%;
}

.faq-wrapper .faq-image-2 .faq-img-2 {
  position: absolute;
  bottom: -22%;
  right: 0;
}

@media (max-width: 991px) {
  .faq-wrapper .faq-image-2 .faq-img-2 {
    bottom: 0;
  }
}

@media (max-width: 575px) {
  .faq-wrapper .faq-image-2 .faq-img-2 {
    max-width: 200px;
  }

  .faq-wrapper .faq-image-2 .faq-img-2 img {
    width: 100%;
    height: 100%;
  }
}

.feature-wrapper {
  border: 1px solid var(--border);
  margin-top: 100px;
  background-color: var(--white);
  z-index: 8;
  position: relative;
}

@media (max-width: 991px) {
  .feature-wrapper {
    margin-top: 80px;
  }
}

.feature-wrapper .feature-image img {
  width: 100%;
  height: 100%;
}

.feature-wrapper .feature-content {
  margin-left: 50px;
  padding-right: 30px;
}

@media (max-width: 1199px) {
  .feature-wrapper .feature-content {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: -20px;
  }
}

.feature-wrapper .feature-content .icon-items {
  margin-top: 40px;
  display: flex;
  gap: 20px;
}

@media (max-width: 767px) {
  .feature-wrapper .feature-content .icon-items {
    flex-wrap: wrap;
  }
}

.feature-wrapper .feature-content .icon-items .icon {
  font-size: 50px;
  color: var(--color-merkos-primary);
}

.feature-wrapper .feature-content .icon-items .content h3 {
  margin-bottom: 5px;
}

.feature-section {
  position: relative;
}

.feature-section p {
  margin: 20px 0;
}

.feature-section .section-title {
  padding-top: 20px;
}

.feature-section .shape-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.feature-section .shape-image img {
  width: 100%;
}

.work-process-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

@media (max-width: 1199px) {
  .work-process-wrapper {
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
  }
}

.work-process-wrapper .work-process-items {
  margin-top: 30px;
  text-align: center;
  position: relative;
  padding: 0 30px;
}

.work-process-wrapper .work-process-items .icon {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  position: relative;
  border-radius: 50%;
  background: #32425a;
  font-size: 35px;
  z-index: 2;
  transition: all 0.4s ease-in-out;
  margin: 0 auto;
}

.work-process-wrapper .work-process-items .icon::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--white);
  width: 78px;
  height: 78px;
  z-index: -1;
  transition: all 0.4s ease-in-out;
  border-radius: 50%;
}

.work-process-wrapper .work-process-items .icon img {
  transition: all 0.4s ease-in-out;
  width: 50px;
  height: auto;
}

.work-process-wrapper .work-process-items .icon .number {
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  border-radius: 50%;
  background-color: var(--white);
  font-size: 16px;
  color: var(--text);
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0px 4px 4px rgba(170, 152, 152, 0.25);
}

.work-process-wrapper .work-process-items .content {
  margin-top: 25px;
}

.work-process-wrapper .work-process-items .content h3 {
  color: var(--white);
  margin-bottom: 13px;
}

.work-process-wrapper .work-process-items .content p {
  color: var(--white);
}

.work-process-wrapper .work-process-items:hover .icon {
  color: var(--white);
}

.work-process-wrapper .work-process-items:hover .icon img {
  filter: grayscale(100%) brightness(300%);
}

.work-process-wrapper .work-process-items:hover .icon::before {
  background-color: var(--color-merkos-primary);
}

.work-process-wrapper .line-shape {
  position: absolute;
  top: 26%;
  left: 60%;
  transform: translate(-50%, -50%);
}

@media (max-width: 1199px) {
  .work-process-wrapper .line-shape {
    display: none;
  }
}

.work-process-button {
  position: relative;
  z-index: 9;
  margin-bottom: -5px;
}

.work-process-button .work-button-items {
  background-repeat: no-repeat;
  text-align: center;
  padding: 23px 50px;
  margin: 0 auto;
  background-size: contain;
}

.work-process-button .work-button-items.style-2 h3 {
  color: var(--white);
}

.work-process-button .nav {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.work-process-button .nav .nav-item .nav-link {
  width: 370px;
  position: relative;
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 370 80"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H330L370 40L330 80H0L40 40L0 0Z"/></svg>');
  mask-repeat: no-repeat;
  mask-position: center center;
  mask-size: contain;
  padding: 24px 50px;
  background-color: var(--white);
  font-size: 24px;
  font-weight: 500;
  color: var(--header);
  height: 80px;
  font-family: "Jost", sans-serif;
  text-align: center;
}

.work-process-button .nav .nav-item .nav-link.active {
  background-color: var(--color-merkos-primary);
  color: var(--white);
}

.work-process-section {
  position: relative;
}

@media screen and (max-width: 575px) {
  .work-process-section {
    padding: 60px 0;
  }
  
}

.work-process-section .shape-image {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.work-process-section .shape-image img {
  width: 100%;
  height: 100%;
}

.counter-items {
  display: flex;
  align-items: center;
  gap: 30px;
}

.counter-items .icon {
  font-size: 80px;
  color: var(--color-merkos-primary);
  width: 70px;
}

.counter-items .content {
  background-color: var(--bg);
  padding: 20px;
  border-radius: 10px;
  margin-left: 70px;
}

.content h2 .count,
.counter-item h3 {
  color: #fff !important;
}

.counter-items .icon img {
  width: 200px;
  min-width: 200px;
  margin-right: 20px;
  border-radius: 15px;
}

.counter-items .content h3 {
  color: var(--text);
  font-weight: 400;
  font-size: 20px;
  margin-top: 5px;
}

.footer-widgets-wrapper {
  padding: 100px 0 130px;
  position: relative;
  z-index: 9;
}

@media (max-width: 1199px) {
  .footer-widgets-wrapper {
    padding: 60px 0 90px;
  }
}

@media (max-width: 991px) {
  .footer-widgets-wrapper {
    padding: 50px 0 70px;
  }
}

.footer-widgets-wrapper .single-footer-widget {
  margin-top: 30px;
}

.footer-widgets-wrapper .single-footer-widget .widget-head {
  margin-bottom: 30px;
}

@media (max-width: 575px) {
  .footer-widgets-wrapper .single-footer-widget .widget-head {
    margin-bottom: 20px;
  }
}

.footer-widgets-wrapper .single-footer-widget .widget-head h3 {
  color: var(--white);
  font-size: 24px;
}

.footer-widgets-wrapper .single-footer-widget .footer-content p {
  color: rgba(255, 255, 255, 0.7);
}

.footer-widgets-wrapper .single-footer-widget .footer-content .social-icon {
  margin-top: 40px;
  gap: 25px;
  position: relative;
  z-index: 9;
}

@media (max-width: 575px) {
  .footer-widgets-wrapper .single-footer-widget .footer-content .social-icon {
    margin-top: 20px;
  }
}

.footer-widgets-wrapper .single-footer-widget .footer-content .social-icon a {
  font-size: 16px;
  transition: all 0.4s ease-in-out;
  text-align: center;
  color: var(--white);
}

.footer-widgets-wrapper .single-footer-widget .footer-content .social-icon a:hover {
  color: var(--color-merkos-primary);
}

.footer-widgets-wrapper .single-footer-widget .footer-content .footer-input {
  position: relative;
  margin-top: 40px;
}

.footer-widgets-wrapper .single-footer-widget .footer-content .footer-input input {
  background: transparent;
  border: none;
  outline: none;
  padding: 18px 20px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  color: rgba(255, 255, 255, 0.8);
}

.footer-widgets-wrapper .single-footer-widget .footer-content .footer-input input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.footer-widgets-wrapper .single-footer-widget .footer-content .footer-input .newsletter-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 60px;
  height: 55px;
  line-height: 60px;
  border-radius: 4px;
  text-align: center;
  background-color: var(--color-merkos-primary);
  color: var(--white);
  bottom: 5px;
}

.footer-widgets-wrapper .single-footer-widget .footer-content .footer-input .newsletter-btn i {
  font-size: 24px;
}

.footer-widgets-wrapper .single-footer-widget .footer-content .footer-input {
  margin-top: 30px;
}

.footer-widgets-wrapper .single-footer-widget .footer-content .footer-input input {
  width: 100%;
  border: none;
  background-color: var(--white);
  position: relative;
  padding: 16px 22px;
  color: var(--text);
  border-radius: 0;
  text-transform: capitalize;
}

.footer-widgets-wrapper .single-footer-widget .footer-content .footer-input input::placeholder {
  color: var(--text);
}

.footer-widgets-wrapper .single-footer-widget .footer-content .footer-input button {
  margin-top: 12px;
  width: 100%;
  line-height: 1.3;
  padding: 19px 40px;
}

.footer-widgets-wrapper .single-footer-widget .list-items li {
  transition: all 0.4s ease-in-out;
  font-weight: 500;
}

.footer-widgets-wrapper .single-footer-widget .list-items li:not(:last-child) {
  margin-bottom: 20px;
}

.footer-widgets-wrapper .single-footer-widget .list-items li a {
  color: rgba(255, 255, 255, 0.7);
}

.footer-widgets-wrapper .single-footer-widget .list-items li a i {
  margin-right: 8px;
}

.footer-widgets-wrapper .single-footer-widget .list-items li:hover {
  margin-left: 5px;
}

.footer-widgets-wrapper .single-footer-widget .list-items li:hover a {
  color: var(--color-merkos-primary);
}

.footer-widgets-wrapper .single-footer-widget .recent-post-area .recent-post-items {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 25px;
}

.footer-widgets-wrapper .single-footer-widget .recent-post-area .recent-post-items .thumb {
  width: 80px;
  height: 80px;
}

.footer-widgets-wrapper .single-footer-widget .recent-post-area .recent-post-items .thumb img {
  width: 100%;
  height: 100%;
}

.footer-widgets-wrapper .single-footer-widget .recent-post-area .recent-post-items .content {
  flex-basis: 50%;
}

.footer-widgets-wrapper .single-footer-widget .recent-post-area .recent-post-items .content .post-date {
  margin-bottom: 10px;
}

.footer-widgets-wrapper .single-footer-widget .recent-post-area .recent-post-items .content .post-date li {
  color: var(--color-merkos-primary);
}

.footer-widgets-wrapper .single-footer-widget .recent-post-area .recent-post-items .content h6 {
  font-size: 18px;
  font-weight: 400;
}

.footer-widgets-wrapper .single-footer-widget .recent-post-area .recent-post-items .content h6 a {
  color: var(--white);
}

.footer-widgets-wrapper .single-footer-widget .recent-post-area .recent-post-items .content h6 a:hover {
  color: var(--color-merkos-primary);
}

.footer-bottom {
  position: relative;
  z-index: 9;
  padding: 30px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.footer-bottom .footer-bottom-wrapper {
  text-align: center;
}

.footer-bottom .footer-bottom-wrapper p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  text-transform: capitalize;
}

.footer-bottom .footer-bottom-wrapper p a {
  color: var(--color-merkos-primary);
}

.footer-section {
  position: relative;
}

.footer-section .shape-1 {
  position: absolute;
  bottom: 0;
  left: 0;
}

.footer-section .shape-2 {
  position: absolute;
  right: 0;
  top: 0;
}

.header-section-2 {
  position: relative;
  margin-bottom: 20px;
}

.header-section-2::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: var(--color-merkos-primary);
  width: 18%;
  z-index: 9;
  clip-path: polygon(0 0, 100% 0%, 80% 100%, 0% 100%);
}

@media (max-width: 1899px) {
  .header-section-2::before {
    display: none;
  }
}

.header-top-section {
  background-color: var(--header);
  padding: 10px 0;
  position: relative;
  z-index: 1;
}

@media (max-width: 991px) {
  .header-top-section {
    display: none;
  }
}

.header-top-section .container-fluid {
  padding: 0 66px;
}

@media (max-width: 1399px) {
  .header-top-section .container-fluid {
    padding: 0 50px;
  }
}

.header-top-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 315px;
}

@media (max-width: 1899px) {
  .header-top-wrapper {
    margin-left: 0;
  }
}

.header-top-wrapper.style-2 {
  margin-left: 0;
}

.header-top-wrapper h6 {
  color: var(--white);
  font-size: 14px;
  font-weight: 400;
  font-family: "Kumbh Sans", sans-serif;
}

.header-top-wrapper h6 img {
  margin-right: 10px;
}

.header-top-wrapper h6 a {
  color: var(--white);
}

.header-top-wrapper .top-right {
  display: flex;
  align-items: center;
  gap: 30px;
}

.header-top-wrapper .top-right .social-icon {
  gap: 15px;
}

.header-top-wrapper .top-right .social-icon span {
  color: var(--white);
  font-weight: 500;
}

.header-top-wrapper .top-right .social-icon a {
  color: var(--white);
}

.header-top-wrapper .top-right .flag-wrap {
  position: relative;
  width: 160px;
}

.header-top-wrapper .top-right .flag-wrap .nice-select {
  padding: 0 0 0 60px;
  background: transparent;
  border: none;
  text-align: center;
  margin: 0 auto;
  position: relative;
  z-index: 999;
  border: none !important;
  padding-bottom: 0 !important;
}

.header-top-wrapper .top-right .flag-wrap .nice-select::after {
  right: 23px;
  border-bottom: 2px solid transparent;
  border-right: 2px solid transparent;
  width: 10px;
  height: 10px;
  top: 15px;
  border-bottom: 2px solid transparent;
  border-color: var(--color-merkos-primary);
  border-right: 2px solid theme-color;
}

.header-top-wrapper .top-right .flag-wrap .nice-select span {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  color: var(--white);
}

.header-top-wrapper .top-right .flag-wrap .flag {
  position: absolute;
  top: 0;
  left: 15px;
  z-index: 1;
}

@media (max-width: 767px) {
  .header-top-wrapper .top-right .flag-wrap .flag {
    display: none;
  }
}

.header-top-wrapper .top-right .flag-wrap .flag img {
  width: 100%;
  height: 100%;
  border: 4px solid var(--white);
  border-radius: 50%;
}

.header-1 {
  margin-left: 315px;
  position: relative;
}

@media (max-width: 1899px) {
  .header-1 {
    margin-left: 0;
  }
}

.header-1 .main-logo {
  position: absolute;
  top: 0;
  left: -215px;
  z-index: 9;
}

@media (max-width: 1899px) {
  .header-1 .main-logo {
    display: none;
  }
}

.header-1 .container-fluid {
  padding: 0 66px;
}

@media (max-width: 1399px) {
  .header-1 .container-fluid {
    padding: 0 50px;
  }
}

@media (max-width: 1899px) {
  .header-1 .header-main .logo {
    display: block !important;
  }
}

@media screen and (max-width: 1025px) {
  .header-1 .header-main .main-menu ul li {
    margin-inline-end: 25px;
  }
}

.header-1 .header-main .main-menu ul li a {
  padding: 17px 0 0;
}

.header-1 .header-main .main-menu ul li .has-homemenu {
  left: -200px;
  top: 185%;
}

@media (max-width: 1600px) {
  .header-1 .header-main .main-menu ul li .has-homemenu {
    left: -300px;
  }
}

.header-1 .header-main .main-menu ul li .submenu {
  top: 185%;
}

.header-1 .header-main .header-right {
  gap: 40px;
  margin-top: 10px;
}

@media (max-width: 991px) {
  .header-1 .header-main .header-right {
    gap: 25px;
  }
}

.header-1 .header-main .header-right .header-button .theme-btn {
  padding: 22px 30px;
}

@media (max-width: 1899px) {
  .header-1 .header-main .header-right .header-button {
    margin-right: 0;
  }
}

@media (max-width: 1399px) {
  .header-1 .header-main .header-right .header-button {
    display: none;
  }
}

.header-1 .header-main .header-right .sidebar__toggle {
  color: var(--color-merkos-primary);
}

.header-2 .header-main .main-menu ul li .has-homemenu {
  top: 115%;
}

.header-2 .header-main .main-menu ul li .submenu {
  top: 115%;
}

@media (max-width: 1199px) {
  .header-2 .header-main .header-right .header-button {
    display: none;
  }
}

.header-2.style-2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  margin-top: 15px;
  transition: all 0.4s ease-in-out;
}

.header-2.style-2 .header-main {
  background-color: var(--white);
  border-radius: 5px;
  padding: 20px 50px;
}

.header-2.style-2 .header-main .main-menu ul li .has-homemenu {
  top: 130%;
}

@media (max-width: 1399px) {
  .header-2.style-2 .header-main .header-right .header-button {
    display: none;
  }
}

@media (max-width: 1199px) {
  .menu-thumb {
    display: none !important;
  }
}

.header-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.header-main .main-menu ul {
  margin-bottom: 0;
}

.header-main .main-menu ul li {
  position: relative;
  list-style: none;
  display: inline-block;
  margin-inline-end: 64px;
}

.header-main .main-menu ul li:last-child {
  margin-inline-end: 0;
}

.header-main .main-menu ul li a {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  color: var(--header);
  padding: 20px 0;
  text-align: left;
  position: relative;
  text-transform: capitalize;
  transition: all 0.4s ease-in-out;
}

.header-main .main-menu ul li a i {
  margin-left: 4px;
}

.header-main .main-menu ul li a:hover {
  color: var(--color-merkos-primary) !important;
}

.header-main .main-menu ul li .submenu {
  position: absolute;
  top: 130%;
  inset-inline-start: 0;
  min-width: 240px;
  background: var(--white);
  padding: 20px 0;
  z-index: 99999;
  visibility: hidden;
  opacity: 0;
  transform-origin: top center;
  color: var(--header);
  box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
  transform: translateY(-10px);
  transition: all 0.4s ease-in-out;
  border-top: 6px solid var(--color-merkos-primary);
}

.header-main .main-menu ul li .submenu li {
  display: block;
  width: 100%;
  margin: 0;
}

.header-main .main-menu ul li .submenu li a {
  position: relative;
  z-index: 11;
  font-size: 16px;
  font-weight: 600;
  color: var(--header);
  line-height: 38px;
  padding: 0px 0px 0px 32px;
  width: 100%;
}

.header-main .main-menu ul li .submenu li a::before {
  content: "";
  position: absolute;
  width: 0px;
  height: 2px;
  background: var(--color-merkos-primary);
  left: 14px;
  bottom: 18px;
  transition: all 0.4s ease-in-out;
}

.header-main .main-menu ul li .submenu li a:hover {
  color: var(--white) !important;
}

.header-main .main-menu ul li .submenu li:last-child a {
  border: none;
}

.header-main .main-menu ul li .submenu li .submenu {
  inset-inline-start: 100%;
  top: 0;
  visibility: hidden;
  opacity: 0;
}

.header-main .main-menu ul li .submenu li:hover>a {
  color: var(--color-merkos-primary) !important;
  margin-left: 10px;
}

.header-main .main-menu ul li .submenu li:hover>a::before {
  width: 10px;
}

.header-main .main-menu ul li .submenu li:hover>a::after {
  color: var(--color-merkos-primary);
}

.header-main .main-menu ul li .submenu li:hover>.submenu {
  -webkit-transform: translateY(1);
  -moz-transform: translateY(1);
  -ms-transform: translateY(1);
  -o-transform: translateY(1);
  transform: translateY(1);
  visibility: visible;
  opacity: 1;
}

.header-main .main-menu ul li .submenu li.has-dropdown>a::after {
  position: absolute;
  top: 50%;
  inset-inline-end: 25px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  color: var(--color-merkos-primary);
}

.header-main .main-menu ul li .has-homemenu {
  width: 800px;
  padding: 30px 30px 10px 30px;
  opacity: 0;
  left: -250px;
  visibility: hidden;
  padding: 30px 30px 10px 30px;
}

.header-main .main-menu ul li .has-homemenu .homemenu-items {
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .header-main .main-menu ul li .has-homemenu .homemenu-items {
    flex-wrap: wrap;
  }
}

.header-main .main-menu ul li .has-homemenu .homemenu-items .homemenu {
  position: relative;
}

.header-main .main-menu ul li .has-homemenu .homemenu-items .homemenu .homemenu-thumb {
  position: relative;
}

.header-main .main-menu ul li .has-homemenu .homemenu-items .homemenu .homemenu-thumb .demo-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  gap: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
  margin-top: 20px;
}

.header-main .main-menu ul li .has-homemenu .homemenu-items .homemenu .homemenu-thumb .demo-button .theme-btn {
  padding: 14px 20px;
  color: var(--white) !important;
  width: initial;
  font-size: 16px;
  text-align: center;
  border-radius: 0px !important;
  background-color: var(--color-merkos-primary);
  line-height: initial;
}

.header-main .main-menu ul li .has-homemenu .homemenu-items .homemenu .homemenu-thumb .demo-button .theme-btn:hover {
  color: var(--white) !important;
  background-color: var(--header) !important;
}

.header-main .main-menu ul li .has-homemenu .homemenu-items .homemenu .homemenu-thumb .demo-button .theme-btn:hover::before {
  background-color: var(--header) !important;
}

.header-main .main-menu ul li .has-homemenu .homemenu-items .homemenu .homemenu-thumb::before {
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      from(rgba(20, 19, 19, 0)),
      to(#5e5ef6));
  background: linear-gradient(to bottom, rgba(99, 92, 92, 0) 0%, #252527 100%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  content: "";
}

.header-main .main-menu ul li .has-homemenu .homemenu-items .homemenu .homemenu-thumb:hover::before {
  visibility: visible;
  opacity: 1;
}

.header-main .main-menu ul li .has-homemenu .homemenu-items .homemenu .homemenu-thumb:hover .demo-button {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.header-main .main-menu ul li .has-homemenu .homemenu-items .homemenu .homemenu-thumb:hover .homemenu-btn {
  opacity: 1;
  visibility: visible;
  bottom: 50%;
  transform: translateY(50%);
}

.header-main .main-menu ul li .has-homemenu .homemenu-items .homemenu .homemenu-thumb img {
  width: 100%;
}

.header-main .main-menu ul li .has-homemenu .homemenu-items .homemenu .homemenu-title {
  text-align: center;
  margin: 15px auto;
  display: inline-block;
  font-size: 16px;
}

.header-main .main-menu ul li:hover>a {
  color: var(--color-merkos-primary);
}

.header-main .main-menu ul li:hover>a::after {
  color: var(--color-merkos-primary);
}

.header-main .main-menu ul li:hover>.submenu {
  visibility: visible;
  opacity: 1;
  transform: translateY(0px);
}

.header-main .header-right {
  gap: 40px;
}

@media (max-width: 1399px) {
  .header-main .header-right {
    gap: 20px;
  }
}

.header-main .sidebar__toggle {
  cursor: pointer;
  font-size: 20px;
}

.sticky {
  position: fixed !important;
  top: 0 !important;
  left: 0;
  width: 100%;
  z-index: 100;
  transition: all 0.9s;
  background-color: var(--white);
  box-shadow: var(--box-shadow);
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.sticky.header-1 {
  margin-left: 0;
}

.sticky.header-1 .main-logo {
  display: none;
}

.sticky.header-1 .header-main .logo {
  display: block !important;
}

.sticky.header-1 .header-main .main-menu ul li a {
  padding-top: initial;
}

.sticky.header-1 .header-main .header-right {
  margin-top: 0;
}

@media (max-width: 1199px) {
  .sticky.header-2 .header-main .header-right .header-button {
    display: none;
  }
}

.sticky.header-2.style-2 {
  margin-top: 0;
}

.sticky.header-2.style-2 .header-main {
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.offcanvas__info {
  background: var(--white) none repeat scroll 0 0;
  border-left: 2px solid var(--theme2);
  position: fixed;
  right: 0;
  top: 0;
  width: 400px;
  height: 100%;
  -webkit-transform: translateX(calc(100% + 80px));
  -moz-transform: translateX(calc(100% + 80px));
  -ms-transform: translateX(calc(100% + 80px));
  -o-transform: translateX(calc(100% + 80px));
  transform: translateX(calc(100% + 80px));
  -webkit-transition: transform 0.45s ease-in-out, opacity 0.45s ease-in-out;
  -moz-transition: transform 0.45s ease-in-out, opacity 0.45s ease-in-out;
  transition: transform 0.45s ease-in-out, opacity 0.45s ease-in-out;
  z-index: 99999;
  overflow-y: scroll;
  overscroll-behavior-y: contain;
  scrollbar-width: none;
}

.offcanvas__info::-webkit-scrollbar {
  display: none;
}

.offcanvas__info.info-open {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

.offcanvas__wrapper {
  position: relative;
  height: 100%;
  padding: 30px 30px;
}

.offcanvas__wrapper .offcanvas__content .text {
  color: var(--text);
}

.offcanvas__wrapper .offcanvas__content .offcanvas__close {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  border-radius: 50%;
  background-color: var(--theme2);
  position: relative;
  z-index: 9;
  cursor: pointer;
}

.offcanvas__wrapper .offcanvas__content .offcanvas__close i {
  color: var(--white);
}

.offcanvas__wrapper .offcanvas__content .offcanvas__contact {
  margin-top: 20px;
}

.offcanvas__wrapper .offcanvas__content .offcanvas__contact ul {
  margin-top: 20px;
}

.offcanvas__wrapper .offcanvas__content .offcanvas__contact ul li {
  font-size: 16px;
  font-weight: 600;
}

.offcanvas__wrapper .offcanvas__content .offcanvas__contact ul li:not(:last-child) {
  margin-bottom: 15px;
}

.offcanvas__wrapper .offcanvas__content .offcanvas__contact ul li .offcanvas__contact-icon {
  margin-right: 20px;
}

.offcanvas__wrapper .offcanvas__content .offcanvas__contact ul li .offcanvas__contact-icon i {
  color: var(--color-merkos-primary);
}

.offcanvas__wrapper .offcanvas__content .offcanvas__contact .header-button .theme-btn {
  width: 100%;
  padding: 16px 40px;
  background-color: var(--color-merkos-primary);
}

@media (max-width: 575px) {
  .offcanvas__wrapper .offcanvas__content .offcanvas__contact .header-button .theme-btn {
    padding: 14px 30px;
  }
}

.offcanvas__wrapper .offcanvas__content .offcanvas__contact .social-icon {
  margin-top: 30px;
  gap: 10px;
}

.offcanvas__wrapper .offcanvas__content .offcanvas__contact .social-icon a {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 16px;
  display: block;
  background: transparent;
  color: var(--header);
  border-radius: 50%;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  text-align: center;
  border: 1px solid var(--border);
}

.offcanvas__wrapper .offcanvas__content .offcanvas__contact .social-icon a:hover {
  background-color: var(--theme2);
  color: var(--white);
}

.offcanvas__overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  background: #151515;
  z-index: 900;
  top: 0;
  opacity: 0;
  visibility: hidden;
  right: 0;
}

.offcanvas__overlay.overlay-open {
  opacity: 0.8;
  visibility: visible;
}

@media (max-width: 450px) {
  .offcanvas__info {
    width: 300px;
  }
}

@media (max-width: 575px) {
  .offcanvas__wrapper {
    padding: 20px;
  }
}

.breadcrumb-wrapper {
  position: relative;
  overflow: hidden;
}

.breadcrumb-wrapper::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: linear-gradient(180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.8) 100%);
}

.page-heading {
  position: relative;
  padding: 100px 0 190px;
  z-index: 9;
}

@media (max-width: 1199px) {
  .page-heading {
    padding: 240px 0 140px;
    text-align: center;
  }
}

@media (max-width: 991px) {
  .page-heading {
    padding: 210px 0 110px;
  }
}

@media (max-width: 767px) {
  .page-heading {
    padding: 170px 0 90px;
  }
}

.page-heading h1 {
  color: var(--white);
  font-size: 70px;
  position: relative;
  z-index: 9;
  font-weight: 600;
}

@media (max-width: 991px) {
  .page-heading h1 {
    font-size: 62px;
  }
}

@media (max-width: 575px) {
  .page-heading h1 {
    font-size: 45px;
  }
}

.page-heading .breadcrumb-items {
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}

@media (max-width: 1199px) {
  .page-heading .breadcrumb-items {
    justify-content: center;
  }
}

@media (max-width: 575px) {
  .page-heading .breadcrumb-items {
    margin-top: 15px;
  }
}

.page-heading .breadcrumb-items li {
  color: var(--color-merkos-primary);
  text-transform: capitalize;
  font-weight: 500;
  font-size: 18px;
}

.page-heading .breadcrumb-items li a {
  color: var(--white);
  transition: all 0.4s ease-in-out;
}

.page-heading .breadcrumb-items li a:hover {
  color: var(--white);
}

.page-heading .breadcrumb-items li i {
  color: var(--white);
}

.page-heading.error-items {
  text-align: center;
  max-width: 815px;
  margin: 0 auto;
}

.page-heading.error-items h1 {
  font-size: 250px;
  font-weight: 600;
  color: var(--white);
  line-height: 1;
}

.page-heading.error-items h2 {
  font-size: 50px;
  color: var(--white);
  text-transform: uppercase;
}

.page-heading.error-items p {
  color: var(--white);
  margin-top: 10px;
}

.page-heading.error-items .email-input {
  margin-top: 50px;
  position: relative;
  margin-bottom: 50px;
}

.page-heading.error-items .email-input input {
  padding-bottom: 18px;
  border: none;
  border-bottom: 1px solid var(--white);
  outline: none;
  width: 100%;
  background-color: transparent;
}

.page-heading.error-items .email-input input::placeholder {
  color: var(--white);
}

.page-heading.error-items .email-input .icon {
  position: absolute;
  top: 10px;
  right: 0;
  color: var(--white);
}

.page-heading.error-items .theme-btn {
  padding: 22px 115px;
}

.page-heading.coming-soon-items {
  text-align: center;
  max-width: 740px;
  margin: 80px auto 0;
  height: 100vh;
}

.page-heading.coming-soon-items p {
  font-size: 18px;
  font-weight: 500;
  font-family: "Jost", sans-serif;
  color: var(--white);
}

.page-heading.coming-soon-items h1 {
  font-size: 120px;
  color: var(--white);
  font-weight: 600;
}

@media (max-width: 991px) {
  .page-heading.coming-soon-items h1 {
    font-size: 90px;
    margin-top: 15px;
  }
}

@media (max-width: 767px) {
  .page-heading.coming-soon-items h1 {
    font-size: 80px;
  }
}

@media (max-width: 575px) {
  .page-heading.coming-soon-items h1 {
    font-size: 60px;
  }
}

.page-heading.coming-soon-items .coming-soon ul {
  display: flex;
  align-items: center;
  gap: 70px;
  justify-content: center;
  margin: 50px auto 0;
}

@media (max-width: 1199px) {
  .page-heading.coming-soon-items .coming-soon ul {
    flex-wrap: wrap;
    gap: 20px;
  }
}

.page-heading.coming-soon-items .coming-soon ul li span {
  font-size: 50px;
  color: var(--white);
  display: inline-block;
  font-weight: 500;
  color: var(--white);
  font-family: "Jost", sans-serif;
}

.page-heading.coming-soon-items .coming-soon ul li p {
  color: var(--white);
  margin-top: 10px;
  text-transform: capitalize;
  font-size: 24px;
  font-weight: 500;
  font-family: "Jost", sans-serif;
}

.page-heading.coming-soon-items .email-input {
  margin-top: 50px;
  position: relative;
  margin-bottom: 50px;
}

.page-heading.coming-soon-items .email-input input {
  padding-bottom: 18px;
  border: none;
  border-bottom: 1px solid var(--white);
  outline: none;
  width: 100%;
  background-color: transparent;
}

.page-heading.coming-soon-items .email-input input::placeholder {
  color: var(--white);
}

.page-heading.coming-soon-items .email-input .icon {
  position: absolute;
  top: 10px;
  right: 0;
  color: var(--white);
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--color-merkos-primary);
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-merkos-primary);
  border-radius: 10px;
}

.fix {
  overflow: hidden;
}

.ralt {
  position: relative;
}

.ml-50 {
  margin-left: 50px;
}

@media (max-width: 1199px) {
  .ml-50 {
    margin-left: 0;
  }
}

.ripple {
  position: relative;
}

.ripple::before,
.ripple::after {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60px;
  height: 60px;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(56, 75, 255, 0.5);
  -webkit-animation: rippleOne 3s infinite;
  animation: rippleOne 3s infinite;
}

.ripple::before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
}

.ripple::after {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
}

.swiper-dot {
  margin-left: 100px;
}

.swiper-dot .swiper-pagination-bullet {
  width: 40px;
  height: 3px;
  transition: 0.6s;
  background-color: #2a374a;
  opacity: 1;
  border-radius: 0;
  position: relative;
}

.swiper-dot .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--color-merkos-primary);
  transition: 0.6s;
  position: relative;
  width: 40px;
}

.swiper-dot-2 .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  transition: 0.6s;
  background-color: var(--white);
  opacity: 1;
  border-radius: 10px;
}

.swiper-dot-2 .swiper-pagination-bullet:not(:last-child) {
  margin-right: 30px;
}

.swiper-dot-2 .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--color-merkos-primary);
  transition: 0.6s;
  position: relative;
}

.swiper-dot-2 .swiper-pagination-bullet.swiper-pagination-bullet-active::before {
  position: absolute;
  width: 22px;
  height: 22px;
  line-height: 22px;
  top: -6px;
  left: -6px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid var(--color-merkos-primary);
  content: "";
}

.array-button .array-prev {
  width: 56px;
  height: 56px;
  line-height: 56px;
  text-align: center;
  background-color: transparent;
  color: var(--white);
  border-radius: 50%;
  transition: all 0.4s ease-in-out;
  border: 1px solid var(--white);
}

.array-button .array-prev:hover {
  background-color: var(--white);
  color: var(--header);
}

.array-button .array-next {
  width: 56px;
  height: 56px;
  line-height: 56px;
  text-align: center;
  background-color: var(--theme2);
  color: var(--white);
  border-radius: 50%;
  border: 1px solid transparent;
  transition: all 0.4s ease-in-out;
}

.array-button .array-next:hover {
  background-color: var(--white);
  color: var(--header);
}

.mt-10 {
  margin-top: 10px;
}

.mt-60 {
  margin-top: 55px;
}

.mb-40 {
  margin-bottom: 40px;
}

/* background */
.bg-cover {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: center;
}

.bg-cover-2 {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: center;
  width: 100%;
  height: 100%;
}

.nice-select {
  background-color: transparent;
  border: transparent;
  float: initial;
  overflow: initial;
  height: initial;
  padding: 0;
  display: inline-flex;
  align-items: center;
  line-height: 150%;
  width: 100%;
  border: none;
}

.nice-select:focus,
.nice-select:hover {
  border-color: transparent;
}

.nice-select::after {
  height: 8px;
  width: 8px;
  right: -25px;
  top: 15px;
  border-color: var(--header);
  border-bottom: 2px solid var(--header);
  border-right: 2px solid var(--header);
}

.nice-select .list {
  width: initial;
  background-color: var(--color-merkos-primary);
  box-shadow: none;
  overflow: initial;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  width: 100%;
  top: 100%;
  padding: 0;
  max-height: 50vh;
  overflow-x: auto;
  right: -50px;
}

.nice-select .list::-webkit-scrollbar {
  width: 2px;
  opacity: 1;
  display: block;
}

.nice-select .list::-webkit-scrollbar-button,
.nice-select .list::-webkit-scrollbar-thumb {
  background: var(--header);
}

.nice-select .option {
  background-color: transparent;
  font-size: 16px;
  line-height: 150%;
  padding: 4px 5px;
  min-height: initial;
  font-weight: 500;
}

.nice-select .option:hover,
.nice-select .option:focus,
.nice-select .option.selected.focus {
  background-color: transparent;
}

.nice-select .current {
  font-weight: 500;
  color: var(--header);
}

.scroll-up {
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px var(--border);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  position: fixed;
  right: 25px;
  bottom: 35px;
  height: 50px;
  width: 50px;
  transition: all 0.4s ease-in-out;
}

.scroll-up::after {
  position: absolute;
  font-family: "Font Awesome 6 free";
  content: "\f062";
  text-align: center;
  line-height: 50px;
  font-weight: 700;
  font-size: 18px;
  color: var(--color-merkos-primary);
  left: 0;
  top: 0;
  height: 50px;
  width: 50px;
  cursor: pointer;
  display: block;
  z-index: 1;
  transition: all 0.4s ease-in-out;
}

.scroll-up svg path {
  fill: none;
}

.scroll-up svg.scroll-circle path {
  stroke: var(--color-merkos-primary);
  stroke-width: 4px;
  box-sizing: border-box;
  transition: all 0.4s ease-in-out;
}

.scroll-up.active-scroll {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.page-nav-wrap ul li {
  display: inline-block;
}

.page-nav-wrap ul li .page-numbers {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: transparent;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  margin: 0 2px;
  border: 1px solid var(--border);
  color: var(--header);
  border-radius: 50%;
}

.page-nav-wrap ul li .page-numbers.current {
  background-color: var(--color-merkos-primary);
  color: var(--white);
}

@media (max-width: 767px) {
  .page-nav-wrap ul li .page-numbers {
    margin-top: 10px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
  }
}

.page-nav-wrap ul li .page-numbers i {
  margin-top: 2px;
}

.page-nav-wrap ul li .page-numbers.icon {
  border: none;
  background-color: transparent;
  color: var(--color-merkos-primary);
}

.page-nav-wrap ul li .page-numbers.icon:hover {
  border: none;
  background-color: transparent;
  color: var(--color-merkos-primary);
}

.page-nav-wrap ul li .page-numbers:hover {
  background-color: var(--color-merkos-primary);
  color: var(--white);
  border: 1px solid transparent;
}

.bor-bottom {
  border-bottom: 1px solid var(--border-2);
}

.pt-100 {
  padding-top: 100px;
}

@media (max-width: 991px) {
  .pt-100 {
    padding-top: 80px;
  }
}

.pb-100 {
  padding-bottom: 100px;
}

@media (max-width: 991px) {
  .pb-100 {
    padding-bottom: 80px;
  }
}

.margin-top-10 {
  margin-top: -10px;
}

.margin-top-5 {
  margin-top: -5px;
}

.margin-top-60 {
  margin-top: -65px;
}

@media (max-width: 1399px) {
  .margin-top-60 {
    margin-top: -25px;
  }
}

@media (max-width: 1199px) {
  .margin-top-60 {
    margin-top: -27px;
  }
}

@media (max-width: 991px) {
  .margin-top-60 {
    margin-top: -75px;
  }
}

@media (max-width: 767px) {
  .margin-top-60 {
    margin-top: -65px;
  }
}

.margin-top-8 {
  margin-top: -10px;
}

.custom-container {
  max-width: 1328px;
  margin: 0 auto;
}

.margin-left-30 {
  margin-left: -40px;
  margin-right: -40px;
}

.hero-1 {
  margin: 0 65px;
  padding: 110px 0 200px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: bottom center;
  overflow: hidden;
  z-index: 9;
  border-radius: 20px;
}

@media (max-width: 1399px) {
  .hero-1 {
    margin: 0 30px;
  }
}

@media (max-width: 575px) {
  .hero-1 {
    margin: 0 0;
  }
}

@media (max-width: 767px) {
  .hero-1 .hero-content {
    text-align: center;
    margin-bottom: 100px;
  }
}

.hero-1 .hero-content h3 {
  font-weight: 500;
  letter-spacing: 9.6px;
  text-transform: uppercase;
  color: var(--color-merkos-primary);
  margin-bottom: 15px;
}

.hero-1 .hero-content h1 {
  color: var(--white);
  margin-bottom: 30px;
}

@media (max-width: 1199px) {
  .hero-1 .hero-content h1 {
    font-size: 70px;
  }
}

@media (max-width: 991px) {
  .hero-1 .hero-content h1 {
    font-size: 60px;
  }
}

@media (max-width: 767px) {
  .hero-1 .hero-content h1 {
    font-size: 50px;
  }
}

@media (max-width: 575px) {
  .hero-1 .hero-content h1 {
    font-size: 40px;
  }
}

.hero-1 .hero-content p {
  max-width: 650px;
  color: var(--white);
  line-height: 181%;
}

.hero-1 .hero-content .hero-button {
  margin-top: 40px;
}

.hero-1 .hero-shape {
  position: absolute;
  bottom: 12px;
  left: -6px;
  z-index: -1;
}

@media (max-width: 1899px) {
  .hero-1 .hero-shape {
    display: none;
  }
}

.hero-1 .video-box {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  bottom: 8%;
}

.hero-1 .video-box .circle-shape {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hero-1 .video-box .video-btn {
  width: 140px;
  height: 140px;
  line-height: 140px;
  text-align: center;
  border-radius: 50%;
  font-size: 16px;
  color: var(--white);
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  position: relative;
}

.hero-1 .video-box .video-btn::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "";
  width: 114px;
  height: 114px;
  border-radius: 50%;
  border: 1px dashed var(--white);
}

.hero-2 {
  position: relative;
}

.hero-2 .swiper-slide-active .slider-image {
  -webkit-transform: scale(1.12);
  -moz-transform: scale(1.12);
  transform: scale(1.12);
}

.hero-2 .slider-image {
  overflow: hidden;
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: -2;
  background-size: cover;
  transform: scale(1);
  -webkit-transition: all 8s ease-out 0s;
  -moz-transition: all 8s ease-out 0s;
  -ms-transition: all 8s ease-out 0s;
  -o-transition: all 8s ease-out 0s;
  transition: all 8s ease-out 0s;
}

.hero-2 .slider-image::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: linear-gradient(0deg,
      rgba(32, 34, 32, 0.52) 0%,
      rgba(32, 34, 32, 0.52) 100%);
}

.hero-2 .slider-image .shape-1 {
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 1199px) {
  .hero-2 .slider-image .shape-1 {
    display: none;
  }
}

.hero-2 .slider-image .shape-2 {
  position: absolute;
  top: 0;
  right: 0;
}

@media (max-width: 1199px) {
  .hero-2 .slider-image .shape-2 {
    display: none;
  }
}

.hero-2 .slider-image .shape-3 {
  position: absolute;
  bottom: 0;
  right: 8%;
}

@media (max-width: 1199px) {
  .hero-2 .slider-image .shape-3 {
    display: none;
  }
}

.hero-2 .hero-content {
  position: relative;
  z-index: 9;
  padding-bottom: 220px;
  padding-top: 220px;
}

@media (max-width: 1199px) {
  .hero-2 .hero-content {
    padding: 190px 0;
  }
}

@media (max-width: 991px) {
  .hero-2 .hero-content {
    padding: 150px 0;
  }
}

@media (max-width: 767px) {
  .hero-2 .hero-content {
    padding: 130px 0;
    text-align: center;
    margin: 0 auto;
  }
}

@media (max-width: 575px) {
  .hero-2 .hero-content {
    padding: 110px 0;
  }
}

.hero-2 .hero-content h3 {
  color: var(--color-merkos-primary);
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 500;
  background-color: var(--white);
  display: inline-block;
  font-size: 18px;
  padding: 10px 20px;
  line-height: 1;
}

.hero-2 .hero-content h1 {
  color: var(--white);
  margin-bottom: 20px;
  font-size: 80px;
  font-weight: 600;
}

@media (max-width: 1399px) {
  .hero-2 .hero-content h1 {
    font-size: 70px;
  }
}

@media (max-width: 991px) {
  .hero-2 .hero-content h1 {
    font-size: 62px;
  }
}

@media (max-width: 767px) {
  .hero-2 .hero-content h1 {
    font-size: 48px;
  }
}

@media (max-width: 575px) {
  .hero-2 .hero-content h1 {
    font-size: 34px;
  }
}

.hero-2 .hero-content p {
  color: var(--white);
}

.hero-2 .hero-content .hero-button {
  margin-top: 50px;
}

@media (max-width: 767px) {
  .hero-2 .hero-content .hero-button {
    margin-top: 30px;
    text-align: center;
  }
}

@media (max-width: 575px) {
  .hero-2 .hero-content .hero-button {
    margin-top: 20px;
  }
}

.hero-2 .swiper-dot-2 {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 99;
  transform: rotate(90deg) translateY(-50%);
}

.hero-3 {
  position: relative;
}

.hero-3 .swiper-dot-2 {
  position: absolute;
  left: 50%;
  bottom: 10%;
  z-index: 99;
  transform: translateX(-50%);
}

@media (max-width: 1199px) {
  .hero-3 .array-button {
    display: none;
  }
}

.hero-3 .array-button .array-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100px;
  z-index: 9;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 0;
}

.hero-3 .array-button .array-prev:hover {
  background-color: var(--color-merkos-primary);
  color: var(--white);
}

.hero-3 .array-button .array-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 100px;
  z-index: 9;
  background-color: var(--color-merkos-primary);
  color: var(--white);
  border: none;
  border-radius: 0;
}

.hero-3 .array-button .array-next:hover {
  background-color: var(--white);
  color: var(--header);
}

.hero-3 .swiper-slide-active .slider-image {
  -webkit-transform: scale(1.12);
  -moz-transform: scale(1.12);
  transform: scale(1.12);
}

.hero-3 .slider-image {
  overflow: hidden;
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: -2;
  background-size: cover;
  transform: scale(1);
  -webkit-transition: all 8s ease-out 0s;
  -moz-transition: all 8s ease-out 0s;
  -ms-transition: all 8s ease-out 0s;
  -o-transition: all 8s ease-out 0s;
  transition: all 8s ease-out 0s;
}

.hero-3 .slider-image::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: linear-gradient(0deg,
      rgba(32, 34, 32, 0.4) 0%,
      rgba(32, 34, 32, 0.4) 100%);
}

.hero-3 .hero-content {
  padding: 330px 0 230px;
  text-align: center;
  position: relative;
  z-index: 2;
}

@media (max-width: 991px) {
  .hero-3 .hero-content {
    padding: 250px 0 180px;
  }
}

@media (max-width: 767px) {
  .hero-3 .hero-content {
    padding: 200px 0 120px;
  }
}

.hero-3 .hero-content h1 {
  font-size: 110px;
  color: var(--white);
  font-weight: 600;
  margin-bottom: 40px;
}

@media (max-width: 1199px) {
  .hero-3 .hero-content h1 {
    font-size: 70px;
  }
}

@media (max-width: 767px) {
  .hero-3 .hero-content h1 {
    font-size: 56px;
    margin-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .hero-3 .hero-content h1 {
    font-size: 44px;
  }
}

.mean-container a.meanmenu-reveal {
  display: none;
}

.mean-container .mean-nav {
  background: none;
  margin-top: 0;
}

.mean-container .mean-bar {
  padding: 0;
  min-height: auto;
  background: none;
}

.mean-container .mean-nav>ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
  display: block !important;
}

.mean-container .mean-nav>ul .homemenu-items {
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: space-between;
}

@media (max-width: 1199px) {
  .mean-container .mean-nav>ul .homemenu-items {
    flex-wrap: wrap;
  }
}

.mean-container .mean-nav>ul .homemenu-items .homemenu {
  position: relative;
}

@media (max-width: 1199px) {
  .mean-container .mean-nav>ul .homemenu-items .homemenu {
    max-width: 300px;
    text-align: center;
    margin: 0 auto;
    border: 1px solid var(--border);
    padding: 10px;
  }
}

.mean-container .mean-nav>ul .homemenu-items .homemenu .homemenu-thumb {
  position: relative;
}

.mean-container .mean-nav>ul .homemenu-items .homemenu .homemenu-thumb .demo-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  gap: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
  margin-top: 20px;
}

.mean-container .mean-nav>ul .homemenu-items .homemenu .homemenu-thumb .demo-button .theme-btn {
  padding: 12px 20px;
  color: var(--white) !important;
  width: initial;
  font-size: 16px;
  text-align: center;
  border-radius: 0;
}

.mean-container .mean-nav>ul .homemenu-items .homemenu .homemenu-thumb .demo-button .theme-btn:hover {
  color: var(--white) !important;
}

.mean-container .mean-nav>ul .homemenu-items .homemenu .homemenu-thumb::before {
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      from(rgba(20, 19, 19, 0)),
      to(#5e5ef6));
  background: linear-gradient(to bottom, rgba(99, 92, 92, 0) 0%, #252527 100%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  content: "";
}

.mean-container .mean-nav>ul .homemenu-items .homemenu .homemenu-thumb:hover::before {
  visibility: visible;
  opacity: 1;
}

.mean-container .mean-nav>ul .homemenu-items .homemenu .homemenu-thumb:hover .demo-button {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.mean-container .mean-nav>ul .homemenu-items .homemenu .homemenu-thumb:hover .homemenu-btn {
  opacity: 1;
  visibility: visible;
  bottom: 50%;
  transform: translateY(50%);
}

.mean-container .mean-nav>ul .homemenu-items .homemenu .homemenu-thumb img {
  width: 100%;
}

.mean-container .mean-nav>ul .homemenu-items .homemenu .homemenu-title {
  text-align: center;
  margin: 15px auto;
  display: inline-block;
  font-size: 16px;
}

.mean-container a.meanmenu-reveal {
  display: none !important;
}

.mean-container .mean-nav ul li a {
  width: 100%;
  padding: 10px 0;
  color: var(--header);
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  text-transform: capitalize;
  border-bottom: 1px solid var(--border) !important;
  border: none;
}

.mean-container .mean-nav ul li a:hover {
  color: var(--color-merkos-primary);
}

.mean-container .mean-nav ul li a:last-child {
  border-bottom: 0;
}

.mean-container .mean-nav ul li a:hover {
  color: var(--theme2);
}

.mean-container .mean-nav ul li a.mean-expand {
  margin-top: 5px;
  padding: 0 !important;
}

.mean-container .mean-nav ul li>a>i {
  display: none;
}

.mean-container .mean-nav ul li>a.mean-expand i {
  display: inline-block;
  font-size: 18px;
}

.mean-container .mean-nav>ul>li:first-child>a {
  border-top: 0;
}

.mean-container .mean-nav ul li a.mean-expand.mean-clicked i {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transition: all 0.4s ease-in-out;
}

.mean-container .mean-nav ul li .mega-menu li a {
  height: 200px;
  width: 100%;
  padding: 0;
  border-top: 0;
  margin-bottom: 20px;
}

.news-card-items {
  margin-top: 30px;
}

.news-card-items .news-image {
  position: relative;
  z-index: 9;
}

.news-card-items .news-image img {
  width: 100%;
  height: 100%;
}

.news-card-items .news-image::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: linear-gradient(180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(16, 32, 57, 0.8) 80%);
}

.news-card-items .news-image .news-content {
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  margin: 0 auto;
  padding: 80px;
}

@media (max-width: 991px) {
  .news-card-items .news-image .news-content {
    padding: 50px;
  }
}

@media (max-width: 767px) {
  .news-card-items .news-image .news-content {
    padding: 40px;
  }
}

@media (max-width: 575px) {
  .news-card-items .news-image .news-content {
    padding: 30px;
  }
}

.news-card-items .news-image .news-content .post-date {
  color: var(--header);
  background-color: var(--white);
  display: inline-block;
  font-weight: 500;
  font-family: "Jost", sans-serif;
  padding-right: 15px;
  display: inline-flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}

.news-card-items .news-image .news-content .post-date i {
  padding: 8px 7px;
  background-color: var(--color-merkos-primary);
  color: var(--white);
  font-size: 20px;
}

.news-card-items .news-image .news-content h3 {
  line-height: 155%;
}

.news-card-items .news-image .news-content h3 a {
  color: var(--white);
}

.news-card-items .news-image .news-content h3 a:hover {
  color: var(--color-merkos-primary);
}

.news-card-items .news-image .news-content .post-items {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin-top: 15px;
}

.news-card-items .news-image .news-content .post-items li a {
  color: var(--white);
}

.news-card-items .news-image .news-content .post-items li a i {
  margin-right: 10px;
}

.news-section {
  position: relative;
  z-index: 9;
}

.news-section::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: var(--white);
  z-index: -1;
  top: 85.7%;
}

.news-box-item .news-image {
  position: relative;
}

.news-box-item .news-image img {
  width: 100%;
  height: 100%;
}

.news-box-item .news-image .post-date {
  position: absolute;
  left: 25px;
  bottom: 7px;
  color: var(--header);
  background-color: var(--white);
  display: inline-block;
  font-weight: 500;
  font-family: "Jost", sans-serif;
  padding-right: 15px;
  display: inline-flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}

.news-box-item .news-image .post-date i {
  padding: 8px 7px;
  background-color: var(--color-merkos-primary);
  color: var(--white);
  font-size: 20px;
}

.news-box-item .news-content {
  margin-top: 20px;
}

.news-box-item .news-content .news-list {
  display: flex;
  align-items: center;
  gap: 40px;
  margin-bottom: 20px;
}

.news-box-item .news-content .news-list li {
  font-size: 16px;
  font-weight: 400;
  color: var(--text);
}

.news-box-item .news-content .news-list li i {
  margin-right: 8px;
}

.news-box-item .news-content h3 {
  margin-bottom: 20px;
}

.news-box-item .news-content h3 a:hover {
  color: var(--color-merkos-primary);
}

.news-details-area .blog-post-details .single-blog-post .post-featured-thumb img {
  width: 100%;
  height: 100%;
}

.news-details-area .blog-post-details .single-blog-post .post-content {
  margin-top: 30px;
}

.news-details-area .blog-post-details .single-blog-post .post-content .post-list {
  gap: 30px;
  margin-bottom: 20px;
}

.news-details-area .blog-post-details .single-blog-post .post-content .post-list li {
  font-size: 14px;
  font-weight: 500;
}

.news-details-area .blog-post-details .single-blog-post .post-content .post-list li i {
  color: var(--color-merkos-primary);
  margin-right: 5px;
}

.news-details-area .blog-post-details .single-blog-post .post-content h3 {
  margin-bottom: 20px;
  font-size: 36px;
  font-weight: 500;
}

@media (max-width: 575px) {
  .news-details-area .blog-post-details .single-blog-post .post-content h3 {
    font-size: 28px;
  }
}

.news-details-area .blog-post-details .single-blog-post .post-content h3 a:hover {
  color: var(--color-merkos-primary);
}

.news-details-area .blog-post-details .single-blog-post .post-content .hilight-text {
  border-left: 4px solid var(--color-merkos-primary);
  padding: 40px;
  background-color: #f4f5f8;
}

.news-details-area .blog-post-details .single-blog-post .post-content .hilight-text p {
  max-width: 650px;
  font-weight: 600;
  text-transform: capitalize;
  font-style: italic;
  line-height: 30px;
  color: var(--header);
}

.news-details-area .blog-post-details .single-blog-post .post-content .hilight-text svg {
  float: right;
  margin-top: -30px;
}

.news-details-area .blog-post-details .single-blog-post .post-content .details-image img {
  width: 100%;
  height: 100%;
}

.news-details-area .blog-post-details .tag-share-wrap {
  border-top: 1px solid var(--border);
  border-bottom: 1px solid var(--border);
  padding: 30px 0;
}

.news-details-area .blog-post-details .tag-share-wrap .tagcloud a {
  display: inline-block;
  padding: 12px 26px;
  line-height: 1;
  background: #f4f5f8;
  margin-right: 8px;
  text-transform: capitalize;
  font-weight: 500;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

@media (max-width: 575px) {
  .news-details-area .blog-post-details .tag-share-wrap .tagcloud a {
    margin-bottom: 5px;
  }
}

.news-details-area .blog-post-details .tag-share-wrap .tagcloud a:hover {
  background-color: var(--color-merkos-primary);
  color: var(--white);
}

.news-details-area .blog-post-details .tag-share-wrap .social-share span {
  font-size: 18px;
  color: var(--header);
  font-weight: 600;
}

.news-details-area .blog-post-details .tag-share-wrap .social-share a {
  font-size: 18px;
  color: var(--header);
}

.news-details-area .blog-post-details .tag-share-wrap .social-share a:not(:last-child) {
  margin-right: 10px;
}

.news-details-area .blog-post-details .tag-share-wrap .social-share a:hover {
  color: var(--color-merkos-primary);
}

.news-details-area .blog-post-details .comments-area {
  margin-top: 40px;
  padding: 50px 40px;
  background-color: #f4f5f8;
}

.news-details-area .blog-post-details .comments-area .comments-heading {
  margin-bottom: 30px;
}

@media (max-width: 575px) {
  .news-details-area .blog-post-details .comments-area .comments-heading {
    margin-bottom: 20px;
  }
}

.news-details-area .blog-post-details .comments-area .comments-heading h3 {
  font-size: 35px;
  font-weight: 500;
}

@media (max-width: 575px) {
  .news-details-area .blog-post-details .comments-area .comments-heading h3 {
    font-size: 28px;
  }
}

.news-details-area .blog-post-details .comments-area .blog-single-comment {
  border-bottom: 1px solid var(--border);
}

@media (max-width: 575px) {
  .news-details-area .blog-post-details .comments-area .blog-single-comment {
    flex-wrap: wrap;
    gap: 20px;
  }
}

.news-details-area .blog-post-details .comments-area .blog-single-comment .content .head .con h5 {
  margin-bottom: 5px;
  font-weight: 600;
}

.news-details-area .blog-post-details .comments-area .blog-single-comment .content .head .star i {
  color: var(--color-merkos-primary);
  font-size: 14px;
}

.news-details-area .blog-post-details .comments-area .blog-single-comment .content .reply {
  padding: 8px 15px;
  font-weight: 400;
  background-color: var(--white);
  color: var(--color-merkos-primary);
}

.news-details-area .blog-post-details .comment-form-wrap h3 {
  font-size: 35px;
  margin-bottom: 30px;
  font-weight: 500;
}

@media (max-width: 575px) {
  .news-details-area .blog-post-details .comment-form-wrap h3 {
    font-size: 28px;
    margin-bottom: 20px;
  }
}

.news-details-area .blog-post-details .comment-form-wrap .form-clt input,
.news-details-area .blog-post-details .comment-form-wrap .form-clt textarea {
  width: 100%;
  outline: none;
  border: none;
  background-color: #f4f5f8;
  border: none;
  padding: 16px 20px;
  font-weight: 500;
}

.news-details-area .blog-post-details .comment-form-wrap .form-clt input::placeholder,
.news-details-area .blog-post-details .comment-form-wrap .form-clt textarea::placeholder {
  color: var(--text);
}

.news-details-area .blog-post-details .comment-form-wrap .form-clt textarea {
  padding-bottom: 150px;
}

.main-sidebar .single-sidebar-widget {
  padding: 40px 30px;
  background-color: #f8f8f8;
  margin-bottom: 30px;
}

.main-sidebar .single-sidebar-widget .wid-title {
  margin-bottom: 25px;
}

.main-sidebar .single-sidebar-widget .wid-title h3 {
  position: relative;
  padding-bottom: 15px;
  font-size: 24px;
  font-weight: 500;
}

.main-sidebar .single-sidebar-widget .wid-title h3::before {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 83px;
  content: "";
  background-color: var(--color-merkos-primary);
}

.main-sidebar .single-sidebar-widget .search-widget form {
  width: 100%;
  position: relative;
}

.main-sidebar .single-sidebar-widget .search-widget form input {
  background-color: var(--white);
  font-size: 15px;
  padding: 20px;
  width: 100%;
  border: none;
  color: var(--text);
}

.main-sidebar .single-sidebar-widget .search-widget form button {
  position: absolute;
  right: 0;
  top: 0;
  width: 70px;
  font-size: 18px;
  height: 100%;
  background-color: var(--color-merkos-primary);
  color: var(--white);
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.main-sidebar .single-sidebar-widget .search-widget form button:hover {
  background-color: var(--header);
}

.main-sidebar .single-sidebar-widget .news-widget-categories ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: var(--white);
  font-weight: 500;
  transition: all 0.4s ease-in-out;
}

.main-sidebar .single-sidebar-widget .news-widget-categories ul li a {
  color: var(--header);
}

.main-sidebar .single-sidebar-widget .news-widget-categories ul li span {
  transition: all 0.4s ease-in-out;
  color: var(--header);
}

.main-sidebar .single-sidebar-widget .news-widget-categories ul li:not(:last-child) {
  margin-bottom: 12px;
}

.main-sidebar .single-sidebar-widget .news-widget-categories ul li:hover {
  background-color: var(--color-merkos-primary);
  color: var(--white);
}

.main-sidebar .single-sidebar-widget .news-widget-categories ul li:hover a {
  color: var(--white);
}

.main-sidebar .single-sidebar-widget .news-widget-categories ul li:hover span {
  color: var(--white);
}

.main-sidebar .single-sidebar-widget .news-widget-categories ul li.active {
  background-color: var(--color-merkos-primary);
}

.main-sidebar .single-sidebar-widget .news-widget-categories ul li.active a {
  color: var(--white);
}

.main-sidebar .single-sidebar-widget .news-widget-categories ul li.active span {
  color: var(--white);
}

.main-sidebar .single-sidebar-widget .recent-post-area .recent-items {
  display: flex;
  align-items: center;
  gap: 20px;
}

.main-sidebar .single-sidebar-widget .recent-post-area .recent-items:not(:last-child) {
  margin-bottom: 20px;
}

.main-sidebar .single-sidebar-widget .recent-post-area .recent-items .recent-content ul {
  margin-bottom: 8px;
}

.main-sidebar .single-sidebar-widget .recent-post-area .recent-items .recent-content ul li i {
  color: var(--color-merkos-primary);
  margin-right: 5px;
}

.main-sidebar .single-sidebar-widget .recent-post-area .recent-items .recent-content h6 {
  font-weight: 500;
}

.main-sidebar .single-sidebar-widget .recent-post-area .recent-items .recent-content h6 a:hover {
  color: var(--color-merkos-primary);
}

.main-sidebar .single-sidebar-widget .tagcloud a {
  display: inline-block;
  padding: 11px 20px;
  line-height: 1;
  font-size: 16px;
  font-weight: 500;
  background: var(--white);
  margin-right: 5px;
  text-transform: capitalize;
  margin-bottom: 10px;
  transition: all 0.4s ease-in-out;
}

.main-sidebar .single-sidebar-widget .tagcloud a:last-child {
  margin-right: 0;
}

.main-sidebar .single-sidebar-widget .tagcloud a:hover {
  background-color: var(--color-merkos-primary);
  color: var(--white);
}

.preloader {
  align-items: center;
  cursor: default;
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9999999;
}

.preloader .animation-preloader {
  z-index: 1000;
}

.preloader .animation-preloader .spinner {
  animation: spinner 1s infinite linear;
  border-radius: 50%;
  border: 3px solid rgba(0, 0, 0, 0.2);
  border-top-color: var(--color-merkos-primary);
  height: 9em;
  margin: 0 auto 3.5em auto;
  width: 9em;
}

@media (max-width: 767px) {
  .preloader .animation-preloader .spinner {
    width: 7.5em;
    height: 7.5em;
    margin: 0 auto 1.5em auto;
  }
}

.preloader .animation-preloader .txt-loading {
  font: bold 5em "Jost", sans-serif, "Kumbh Sans", sans-serif;
  text-align: center;
  user-select: none;
}

@media (max-width: 767px) {
  .preloader .animation-preloader .txt-loading {
    font-size: 2.5em;
  }
}

.preloader .animation-preloader .txt-loading .letters-loading {
  color: var(--color-merkos-primary);
  position: relative;
}

.preloader .animation-preloader .txt-loading .letters-loading:nth-child(2):before {
  animation-delay: 0.2s;
}

.preloader .animation-preloader .txt-loading .letters-loading:nth-child(3):before {
  animation-delay: 0.4s;
}

.preloader .animation-preloader .txt-loading .letters-loading:nth-child(4):before {
  animation-delay: 0.6s;
}

.preloader .animation-preloader .txt-loading .letters-loading:nth-child(5):before {
  animation-delay: 0.8s;
}

.preloader .animation-preloader .txt-loading .letters-loading:nth-child(6):before {
  animation-delay: 1s;
}

.preloader .animation-preloader .txt-loading .letters-loading:nth-child(7):before {
  animation-delay: 1.2s;
}

.preloader .animation-preloader .txt-loading .letters-loading:nth-child(8):before {
  animation-delay: 1.4s;
}

.preloader .animation-preloader .txt-loading .letters-loading::before {
  animation: letters-loading 4s infinite;
  color: var(--header);
  content: attr(data-text-preloader);
  left: 0;
  opacity: 0;
  font-family: "Jost", sans-serif;
  position: absolute;
  top: -3px;
  transform: rotateY(-90deg);
}

.preloader p {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 8px;
  color: var(--color-merkos-primary);
}

.preloader .loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  z-index: 1;
  pointer-events: none;
}

.preloader .loader .row {
  height: 100%;
}

.preloader .loader .loader-section {
  padding: 0px;
}

.preloader .loader .loader-section .bg {
  background-color: var(--white);
  height: 100%;
  left: 0;
  width: 100%;
  transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
}

.preloader.loaded .animation-preloader {
  opacity: 0;
  transition: 0.3s ease-out;
}

.preloader.loaded .loader-section .bg {
  width: 0;
  transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
}

.search-wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  background-color: rgba(255, 255, 255, 0.9);
}

.search-wrap .search-inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.search-wrap .search-cell {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}

.search-wrap .search-field-holder {
  width: 50%;
  margin: auto;
  position: relative;
  animation: slideInUp 0.3s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .search-wrap .search-field-holder {
    width: 70%;
  }
}

@media (max-width: 575px) {
  .search-wrap .search-field-holder {
    width: 80%;
  }
}

.search-wrap .main-search-input {
  width: 100%;
  height: 70px;
  border: 0;
  padding: 0 50px;
  text-transform: capitalize;
  background: transparent;
  font-size: 25px;
  color: var(--color-merkos-primary);
  border-bottom: 2px solid var(--color-merkos-primary);
  text-align: center;
  letter-spacing: 2px;
}

@media (max-width: 575px) {
  .search-wrap .main-search-input {
    height: 50px;
    padding: 0 0;
    line-height: 50px;
    font-size: 18px;
  }
}

.search-wrap input.form-control,
.search-wrap input.form-control:focus {
  background-color: var(--color-merkos-primary);
}

input.main-search-input::placeholder {
  color: var(--color-merkos-primary);
  opacity: 1;
  font-size: 25px;
}

@media (max-width: 575px) {
  input.main-search-input::placeholder {
    font-size: 18px;
  }
}

.search-close {
  position: absolute;
  top: 50px;
  right: 50px;
  font-size: 30px;
  color: var(--color-merkos-primary);
  cursor: pointer;
}

.mouse-cursor {
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  border-radius: 50%;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  visibility: hidden;
}

.cursor-inner {
  width: 6px;
  height: 6px;
  z-index: 10000001;
  background-color: var(--theme2);
  -webkit-transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
    margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
    margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
    margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.cursor-inner.cursor-hover {
  margin-left: -35px;
  margin-top: -35px;
  width: 70px;
  height: 70px;
  background-color: var(--theme2);
  opacity: 0.3;
}

.cursor-outer {
  margin-left: -12px;
  margin-top: -12px;
  width: 30px;
  height: 30px;
  border: 1px solid var(--theme2);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 10000000;
  opacity: 0.5;
  -webkit-transition: all 0.08s ease-out;
  -o-transition: all 0.08s ease-out;
  transition: all 0.08s ease-out;
}

.cursor-outer.cursor-hover {
  opacity: 0;
}

.back-to-top {
  border-radius: 50%;
  background-color: var(--color-merkos-primary);
  width: 50px;
  height: 50px;
  line-height: 50px;
  color: var(--white);
  font-size: 18px;
  position: fixed;
  display: inline-block;
  z-index: 99;
  right: 30px;
  bottom: 30px;
  transition: all 0.4s ease-in-out;
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
}

.back-to-top:hover {
  background-color: var(--header);
}

.back-to-top.show {
  opacity: 1;
  visibility: visible;
  transform: translate(0);
}

.project-items {
  margin-top: 30px;
}

.project-items .project-image {
  overflow: hidden;
  position: relative;
  transition: all 0.4s ease-in-out;
  display: block;
}

/* General style for project images */
.project-items .project-image {
  width: 100%;
  height: auto;
  /* Adjust height automatically to preserve aspect ratio */
}

.project-items .project-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Maintain cover by default */
}

/* Media query for small screens (max-width: 575px) */
@media (max-width: 575px) {
  .project-items .project-image {
    height: auto;
    /* Remove fixed height to allow the image to scale naturally */
  }

  .project-items .project-image img {
    width: 100%;
    height: auto;
    /* Auto height to preserve aspect ratio */
    object-fit: contain !important;
    /* Contain images within the given area without cropping */
  }
}

.project-items .project-image::before {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 0%;
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.9s;
}

.project-items .project-image .project-content {
  position: absolute;
  bottom: 0;
  border-radius: 5px;
  background: rgba(16, 32, 57, 0.6);
  backdrop-filter: blur(4.9000000954px);
  padding: 25px 100px 25px 35px;
  left: 40px;
  bottom: -320px;
  transition: all 0.9s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

@media (max-width: 575px) {
  .project-items .project-image .project-content {
    left: 10px;
    padding: 20px 60px 15px 25px;
  }

  .project-items .project-image .project-content .icon {
    right: -20px !important;
    top: 10px !important;
    width: 40px !important;
    height: 40px !important;
  }
}

.project-items .project-image .project-content h3 {
  position: relative;
}

.project-items .project-image .project-content h3 a {
  color: var(--white);
}

.project-items .project-image .project-content h3::before {
  position: absolute;
  content: "";
  transform: translateY(-50%);
  top: 50%;
  left: -20px;
  width: 5px;
  height: 55px;
  border-radius: 2.5px;
  background-color: var(--color-merkos-primary);
}

.project-items .project-image .project-content .icon {
  position: absolute;
  right: -22px;
  top: 17px;
  width: 42px;
  height: 42px;
  line-height: 42px;
  border-radius: 50%;
  background-color: var(--white);
  color: var(--text);
  display: inline-block;
  text-align: center;
  font-size: 18px;
  transition: all 0.4s ease-in-out;
}

.project-items .project-image .project-content .icon i {
  transform: rotate(40deg);
}

.project-items:hover .project-image::before {
  height: 100%;
}

.project-items:hover .project-image .project-content {
  bottom: 40px;
  opacity: 1;
  visibility: visible;
}

@media (max-width: 575px) {
  .project-items:hover .project-image .project-content {
    bottom: 20px;
  }
}

.project-items:hover .project-image .project-content h3 a {
  color: var(--color-merkos-primary);
}

.project-items:hover .project-image .project-content .icon {
  color: var(--white);
  background-color: var(--color-merkos-primary);
}

.project-items:hover .project-image .project-content .icon i {
  transform: rotate(0);
}

.project-items.style-2 .project-image::before {
  background: linear-gradient(0deg,
      rgba(16, 32, 57, 0.6) 0%,
      rgba(16, 32, 57, 0.6) 100%);
}

.project-items.style-2 .project-image .project-content {
  left: 20px;
}

.project-items.style-2:hover .project-image .project-content {
  bottom: 20px;
}

.project-items.style-3 .project-image::before {
  background: linear-gradient(0deg,
      rgba(16, 32, 57, 0.5) 0%,
      rgba(16, 32, 57, 0.5) 100%);
}

.project-section .project-slider {
  margin-left: -300px;
  margin-right: -300px;
}

@media (max-width: 1199px) {
  .project-section .project-slider {
    margin-left: 0;
    margin-right: 0;
  }
}

.project-details-wrapper .project-details-thumb {
  margin-bottom: 50px;
}

.project-details-wrapper .project-details-thumb img {
  width: 100%;
  height: 100%;
}

.project-details-wrapper .project-details-content span {
  font-size: 16px;
  font-weight: 400;
  color: var(--header);
  display: inline-block;
  margin-bottom: 20px;
}

.project-details-wrapper .project-details-content h2 {
  font-size: 35px;
  margin-bottom: 30px;
  font-weight: 500;
}

.project-details-wrapper .project-details-content .icon-item {
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
}

.project-details-wrapper .project-details-content .icon-item:last-child {
  margin-bottom: 0;
}

.project-details-wrapper .project-details-content .icon-item .content h3 {
  margin-bottom: 7px;
}

.project-details-wrapper .project-details-content .project-arrow-item {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.project-details-wrapper .project-details-content .project-arrow-item i {
  width: 97px;
  border-radius: 57px;
  border: 1px solid var(--text);
  padding: 13px 14px;
  text-align: center;
  font-size: 22px;
  transition: all 0.4s ease-in-out;
}

.project-details-wrapper .project-details-content .project-arrow-item i:hover {
  background-color: var(--color-merkos-primary);
  color: var(--white);
  border: 1px solid var(--color-merkos-primary);
}

.project-details-wrapper .project-information-item {
  background: #f8f8f8;
  padding: 60px;
  position: relative;
  z-index: 9;
}

@media (max-width: 1199px) {
  .project-details-wrapper .project-information-item {
    padding: 50px;
  }
}

@media (max-width: 767px) {
  .project-details-wrapper .project-information-item {
    padding: 40px;
  }
}

@media (max-width: 575px) {
  .project-details-wrapper .project-information-item {
    padding: 30px;
  }
}

.project-details-wrapper .project-information-item .project-shape {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

@media (max-width: 1199px) {
  .project-details-wrapper .project-information-item .project-shape {
    display: none;
  }
}

.project-details-wrapper .project-information-item h2 {
  font-size: 35px;
  font-weight: 500;
  border-bottom: 1px solid var(--border);
  padding-bottom: 30px;
}

.project-details-wrapper .project-information-item .project-list {
  padding: 30px;
}

.project-details-wrapper .project-information-item .project-list li {
  font-size: 16px;
  font-weight: 400;
  color: var(--header);
  margin-bottom: 30px;
}

.project-details-wrapper .project-information-item .project-list li:last-child {
  margin-bottom: 0;
}

.project-details-wrapper .project-information-item .project-list li span {
  font-weight: 700;
}

.project-details-wrapper .project-information-item .social-icon {
  gap: 30px;
}

@media (max-width: 1199px) {
  .project-details-wrapper .project-information-item .social-icon {
    flex-wrap: wrap;
    gap: 15px;
  }
}

.project-details-wrapper .project-information-item .social-icon a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: var(--text);
  border-radius: 100%;
  color: var(--white);
  font-size: 16px;
  text-align: center;
  transition: all 0.4s ease-in-out;
}

.project-details-wrapper .project-information-item .social-icon a:hover {
  background-color: var(--color-merkos-primary);
}

.project-section {
  background-color: var(--bg);
  padding: 50px 0;
  padding-top: 50px !important;
}

.project-section h2 {
  color: #fff;
}

.project-section .nav {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-bottom: 40px;
}

.project-section .nav .nav-item .nav-link {
  color: var(--header);
  border: 1px solid var(--text);
  padding: 18px 30px;
  line-height: 1;
  font-size: 24px;
  font-weight: 500;
  font-family: "Jost", sans-serif;
}

.project-section .nav .nav-item .nav-link.active {
  background-color: var(--color-merkos-primary);
  color: var(--white);
}

.section-title {
  position: relative;
  z-index: 2;
  margin-bottom: 30px;
  margin-top: -1px;
}

@media (max-width: 767px) {
  .section-title {
    margin-bottom: 0;
  }
}

.section-title span {
  font-size: 16px;
  font-weight: 600;
  font-family: "Kumbh Sans", sans-serif;
  color: var(--color-merkos-primary);
  display: inline-block;
  margin-bottom: 20px;
  line-height: 1;
  letter-spacing: 3.2px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.section-title span img {
  margin-right: 10px;
  width: 10px;
  height: auto;
}

.section-title span i {
  color: var(--color-merkos-primary);
  margin-right: 10px;
}

.section-title-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 9;
}

@media (max-width: 991px) {
  .section-title-area {
    flex-wrap: wrap;
    gap: 30px;
  }
}

.center {
  text-align: center;
  margin: 0 auto;
}

.section-bg {
  background-color: var(--bg);
}

@media (max-width: 575px) {
  .section-bg {
    padding: 35px !important;
  }
}

.section-padding {
  padding: 130px 0;
}

@media (max-width: 1199px) {
  .section-padding {
    padding: 100px 0;
  }
}

@media (max-width: 991px) {
  .section-padding {
    padding: 80px 0;
  }
}

.service-box-items {
  padding: 35px 33px;
  background-color: var(--white);
  box-shadow: var(--box-shadow);
  position: relative;
}

.service-box-items.style-2 {
  margin-top: 30px;
}

.service-box-items .content h3 {
  margin-bottom: 15px;
}

.service-box-items .content h3 a:hover {
  color: var(--color-merkos-primary);
}

.service-box-items .content p {
  margin-bottom: 25px;
}

.service-box-items .icon {
  position: absolute;
  bottom: 30px;
  right: 25px;
  font-size: 50px;
  color: var(--black);
  z-index: 1;
}

.service-box-items .icon::before {
  position: absolute;
  bottom: -6px;
  right: 0;
  width: 30px;
  height: 30px;
  content: "";
  background-color: var(--color-merkos-primary);
  border-radius: 50%;
  z-index: -1;
}

.service-section {
  position: relative;
  margin-bottom: 60px;
}

@media (max-width: 1199px) {
  .service-section {
    margin-bottom: 30px;
  }
}

@media (max-width: 991px) {
  .service-section {
    margin-bottom: 0;
  }
}

.service-section .nexava-shape {
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

.service-card-items {
  margin-top: 30px;
}

.service-card-items .service-image {
  position: relative;
}

.service-card-items .service-image img {
  width: 100%;
  height: 100%;
}

.service-card-items .service-content {
  background-color: var(--color-merkos-primary);
  padding: 18px 43px;
  display: inline-flex;
  align-items: center;
  gap: 20px;
  position: absolute;
  bottom: -42px;
  left: 0;
  transition: all 0.4s ease-in-out;
}

.service-card-items .service-content h3 a {
  color: var(--white);
}

.service-card-items .service-content .arrow-icon {
  width: 46px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  background-color: var(--header);
  color: var(--white);
  border-radius: 50%;
  position: absolute;
  right: -25px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
}

.service-card-items:hover .service-content {
  bottom: 0;
}

.service-card-items:hover .service-content .arrow-icon {
  opacity: 1;
  visibility: visible;
}

.service-section-2 {
  margin-bottom: 42px;
  position: relative;
}

.service-section-2 .arrow-shape {
  position: absolute;
  right: 0;
  top: 5%;
}

.service-section-3 {
  position: relative;
}

.service-section-3 .service-bg-shape {
  position: absolute;
  top: -180px;
  left: 0;
  right: 0;
  z-index: -1;
}

.service-section-3 .service-bg-shape img {
  width: 100%;
  height: 100%;
}

.service-section-3 .service-wrapper-2 {
  margin-right: -8%;
}

@media (max-width: 1399px) {
  .service-section-3 .service-wrapper-2 {
    margin-right: 0;
  }
}

.service-left-item .service-image {
  margin-left: -79%;
  height: 370px;
}

@media (max-width: 1199px) {
  .service-left-item .service-image {
    margin-left: 0;
  }
}

.service-left-item .service-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.service-details-wrapper .service-details-content h2 {
  font-size: 50px;
  margin-bottom: 20px;
}

@media (max-width: 991px) {
  .service-details-wrapper .service-details-content h2 {
    font-size: 32px;
  }
}

@media (max-width: 1199px) {
  .service-details-wrapper .service-details-content h2 {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .service-details-wrapper .service-details-content h2 {
    font-size: 28px;
  }
}

@media (max-width: 575px) {
  .service-details-wrapper .service-details-content h2 {
    font-size: 24px;
  }
}

.service-details-wrapper .service-details-content .details-thumb img {
  width: 100%;
  height: 100%;
}

.service-details-wrapper .service-details-content .list {
  margin-top: 30px;
  margin-bottom: 40px;
}

.service-details-wrapper .service-details-content .list li {
  font-size: 16px;
  color: var(--header);
  font-weight: 600;
}

.service-details-wrapper .service-details-content .list li:not(:last-child) {
  margin-bottom: 10px;
}

.service-details-wrapper .service-details-content .list li i {
  color: var(--color-merkos-primary);
  margin-right: 5px;
}

.service-details-wrapper .service-details-content .list-2 {
  margin-top: 10px;
}

.service-details-wrapper .service-details-content .list-2 li {
  color: var(--header);
}

.service-details-wrapper .service-details-content .list-2 li i {
  color: var(--color-merkos-primary);
  margin-right: 10px;
}

.service-details-wrapper .service-details-content .list-2 li:not(:last-child) {
  margin-bottom: 10px;
}

.service-details-wrapper .service-details-content .download-file {
  background-color: #f7f7f7;
  padding: 35px 30px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 1399px) {
  .service-details-wrapper .service-details-content .download-file {
    padding: 30px 25px;
    gap: 20px;
    justify-content: center;
    text-align: center;
  }
}

.service-details-wrapper .service-details-content .download-file .download-button {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.service-details-wrapper .service-details-sidebar .sidebar-widget {
  background-color: #f7f7f7;
  padding: 30px;
  margin-bottom: 30px;
}

.service-details-wrapper .service-details-sidebar .sidebar-widget h3 {
  line-height: 1;
  margin-bottom: 30px;
}

.service-details-wrapper .service-details-sidebar .sidebar-widget ul li {
  padding: 18px 20px;
  background-color: var(--bg);
  font-family: "Jost", sans-serif;
  background-color: var(--white);
  transition: all 0.4s ease-in-out;
  font-size: 18px;
  font-weight: 500;
}

.service-details-wrapper .service-details-sidebar .sidebar-widget ul li:not(:last-child) {
  margin-bottom: 10px;
}

.service-details-wrapper .service-details-sidebar .sidebar-widget ul li a {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.service-details-wrapper .service-details-sidebar .sidebar-widget ul li:hover {
  background-color: var(--color-merkos-primary);
}

.service-details-wrapper .service-details-sidebar .sidebar-widget ul li:hover a {
  color: var(--white);
}

.service-details-wrapper .service-details-sidebar .sidebar-widget .contact-bg {
  padding: 50px 20px;
  position: relative;
  z-index: 9;
}

.service-details-wrapper .service-details-sidebar .sidebar-widget .contact-bg::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
  background-color: var(--color-merkos-primary);
  opacity: 0.9;
}

.service-details-wrapper .service-details-sidebar .sidebar-widget .contact-bg h5 {
  margin-bottom: 10px;
  color: var(--white);
}

.service-details-wrapper .service-details-sidebar .sidebar-widget .contact-bg h4 {
  margin-bottom: 10px;
}

.service-details-wrapper .service-details-sidebar .sidebar-widget .contact-bg h4 a {
  color: var(--white);
}

.service-details-wrapper .service-details-sidebar .sidebar-widget .contact-bg p {
  color: var(--white);
}

.team-box-items {
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 40px;
  border-radius: 10px;
  border: 1px solid #efe2e2;
  padding: 30px;
}

@media (max-width: 767px) {
  .team-box-items {
    flex-wrap: wrap;
    gap: 30px;
  }
}

.team-box-items .team-image img {
  border-radius: 10px;
}

.team-box-items .team-content h3 a:hover {
  color: var(--color-merkos-primary);
}

.team-box-items .team-content span {
  display: inline-block;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.team-box-items .team-content .social-icon {
  margin-top: 20px;
  font-size: 20px;
  gap: 20px;
}

.team-box-items .team-content .social-icon a:hover {
  color: var(--color-merkos-primary);
}

.team-box-items-2 {
  margin-top: 30px;
  border-radius: 10px;
  border: 1px solid #efe2e2;
  text-align: center;
}

.team-box-items-2 .team-image {
  padding: 29px 29px 0;
}

.team-box-items-2 .team-image img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.team-box-items-2 .team-content {
  padding: 29px 0px;
}

.team-box-items-2 .team-content h3 a:hover {
  color: var(--color-merkos-primary);
}

.team-box-items-2 .team-content span {
  display: inline-block;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.team-box-items-2 .team-content .social-icon {
  margin-top: 10px;
  font-size: 20px;
  gap: 20px;
  justify-content: center;
}

.team-box-items-2 .team-content .social-icon a:hover {
  color: var(--color-merkos-primary);
}

.team-details-wrapper {
  margin-bottom: -55px;
}

@media (max-width: 991px) {
  .team-details-wrapper {
    margin-bottom: -38px;
  }
}

.team-details-wrapper .details-image-items .details-image {
  padding: 40px;
  border: 1px solid #efe2e2;
  border-radius: 10px;
}

.team-details-wrapper .details-image-items .details-image img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.team-details-wrapper .details-image-items .details-information {
  margin-top: 40px;
}

.team-details-wrapper .details-image-items .details-information .details-title {
  border-bottom: 1px solid #efe2e2;
  padding-bottom: 40px;
}

.team-details-wrapper .details-image-items .details-information .details-title h2 {
  font-size: 35px;
  font-weight: 500;
  margin-bottom: 10px;
}

.team-details-wrapper .details-image-items .details-information .details-title span {
  font-size: 18px;
  display: inline-block;
}

.team-details-wrapper .details-image-items .details-information .info-list li {
  font-size: 24px;
  font-family: "Jost", sans-serif;
  padding: 30px 0;
  border-bottom: 1px solid #efe2e2;
  color: var(--header);
}

.team-details-wrapper .details-image-items .details-information .info-list li span {
  font-size: 22px;
  color: var(--text);
  display: block;
  margin-bottom: 15px;
}

.team-details-wrapper .details-image-items .details-information .info-list li a {
  color: var(--header);
}

.team-details-wrapper .details-image-items .details-information .social-icon {
  margin-top: 30px;
  gap: 15px;
}

.team-details-wrapper .details-image-items .details-information .social-icon a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  background-color: var(--text);
  color: var(--white);
  display: inline-block;
  text-align: center;
}

.team-details-wrapper .details-image-items .details-information .social-icon a:hover {
  background-color: var(--color-merkos-primary);
}

.team-details-wrapper .team-details-content h2 {
  font-size: 35px;
  font-weight: 500;
  margin-bottom: 15px;
}

.team-details-wrapper .team-details-content .list-items {
  margin-top: 30px;
  margin-bottom: 50px;
}

@media (max-width: 991px) {
  .team-details-wrapper .team-details-content .list-items {
    margin-bottom: 30px;
  }
}

.team-details-wrapper .team-details-content .list-items li {
  font-size: 16px;
  font-weight: 600;
  color: var(--header);
}

.team-details-wrapper .team-details-content .list-items li i {
  color: var(--color-merkos-primary);
  margin-right: 10px;
}

.team-details-wrapper .team-details-content .list-items li:not(:last-child) {
  margin-bottom: 15px;
}

.team-details-wrapper .team-details-content .text-title {
  padding-bottom: 30px;
  border-bottom: 1px solid #efe2e2;
}

.team-details-wrapper .team-details-content .list-2 {
  margin-top: 30px;
  margin-bottom: 50px;
}

@media (max-width: 991px) {
  .team-details-wrapper .team-details-content .list-2 {
    margin-bottom: 30px;
  }
}

.team-details-wrapper .team-details-content .list-2 li {
  color: var(--text);
}

.team-details-wrapper .team-details-content .list-2 li:not(:last-child) {
  margin-bottom: 25px;
}

.team-details-wrapper .team-details-content .list-2 li span {
  font-weight: 500;
  color: var(--header);
  margin-bottom: 10px;
  display: block;
}

.team-details-wrapper .team-details-content .list-2 li span i {
  margin-right: 14px;
}

.team-details-wrapper .team-details-content .progress-wrap {
  margin-top: 40px;
  margin-bottom: 50px;
}

@media (max-width: 991px) {
  .team-details-wrapper .team-details-content .progress-wrap {
    margin-bottom: 30px;
    margin-top: 30px;
  }
}

.team-details-wrapper .team-details-content .progress-wrap .pro-items {
  width: 100%;
}

.team-details-wrapper .team-details-content .progress-wrap .pro-items:not(:last-child) {
  margin-bottom: 20px;
}

.team-details-wrapper .team-details-content .progress-wrap .pro-items .pro-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.team-details-wrapper .team-details-content .progress-wrap .pro-items .pro-head .title {
  font-size: 16px;
  color: var(--header);
  font-weight: 500;
}

.team-details-wrapper .team-details-content .progress-wrap .pro-items .pro-head .point {
  font-size: 16px;
  color: var(--text);
}

.team-details-wrapper .team-details-content .progress-wrap .pro-items .progress {
  background: transparent;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  display: flex;
  height: 12px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid rgba(34, 48, 53, 0.2);
}

.team-details-wrapper .team-details-content .progress-wrap .pro-items .progress-value {
  animation: load 3s normal forwards;
  border-radius: 0;
  background: var(--color-merkos-primary);
  height: 12px;
  width: 0;
  border-radius: 10px;
}

.team-details-wrapper .team-details-content .progress-wrap .pro-items .style-two {
  animation: load2 3s normal forwards;
}

.team-details-wrapper .team-details-content .progress-wrap .pro-items .style-three {
  animation: load3 3s normal forwards;
}

@keyframes load {
  0% {
    width: 0;
  }

  100% {
    width: 90%;
  }
}

@keyframes load2 {
  0% {
    width: 0;
  }

  100% {
    width: 95%;
  }
}

@keyframes load3 {
  0% {
    width: 0;
  }

  100% {
    width: 85%;
  }
}

.testimonial-card-items {
  margin-top: 30px;
  position: relative;
  z-index: 9;
}

.testimonial-card-items::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: var(--white);
  border: 1px solid var(--border);
  border-radius: 5px;
  z-index: -1;
  top: 90px;
  height: initial;
}

.testimonial-card-items .client-img {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  text-align: center;
  margin: 0 auto;
  position: relative;
}

@media (max-width: 767px) {
  .testimonial-card-items .client-img {
    width: 140px;
    height: 140px;
  }
}

.testimonial-card-items .client-img .icon {
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  font-size: 24px;
  background-color: var(--color-merkos-primary);
  color: var(--white);
  border: 1px solid var(--border);
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
}

.testimonial-card-items p {
  padding: 50px 50px 50px;
}

.testimonial-card-items .client-info {
  text-align: center;
  background-color: var(--color-merkos-primary);
  padding: 22px 20px;
}

.testimonial-card-items .client-info span {
  color: var(--white);
  line-height: 1;
}

.testimonial-card-items .client-info h3 {
  color: var(--white);
  margin-top: 10px;
}

.testimonial-card-items.style-2 {
  text-align: center;
}

.testimonial-card-items.style-2 .client-img {
  width: 150px;
  height: 150px;
}

.testimonial-card-items.style-2 h5 {
  margin-top: 30px;
}

.testimonial-card-items.style-2 .star {
  color: var(--theme2);
  margin: 10px 0;
}

.testimonial-card-items.style-2 p {
  padding: 0 40px;
  color: var(--header);
  font-weight: 500;
  font-size: 17px;
}

.testimonial-card-items.style-2 .client-info {
  background-color: transparent;
  padding: 40px 20px;
}

.testimonial-card-items.style-2 .client-info span {
  color: var(--text);
}

.testimonial-card-items.style-2 .client-info h3 {
  color: var(--header);
  margin: 0;
  font-size: 18px;
}

.testimonial-card-items.style-2 .client-info p {
  margin-top: 10px;
}

.scrolling-wrap {
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  gap: 20px;
}

.scrolling-wrap .comm {
  display: flex;
  align-items: center;
  animation: scroll 60s linear infinite;
  gap: 30px;
  cursor: pointer;
}

.scrolling-wrap img {
  width: 40px;
  height: auto;
}

.scrolling-wrap .comm .cmn-textslide {
  font-size: 36px;
  font-weight: 700;
  line-height: 95px;
  text-transform: uppercase;
  color: var(--white);
  font-family: "Jost", sans-serif;
  letter-spacing: 1.44px;
}

.scrolling-wrap .comm .cmn-textslide.text-custom-storke {
  -webkit-text-stroke: 1px var(--white);
  -webkit-text-fill-color: transparent;
}

.scrolling-wrap:hover .comm {
  animation-play-state: paused;
}

@media (max-width: 1399px) {
  .scrolling-wrap .comm {
    gap: 20px;
  }
}

@media (max-width: 991px) {
  .scrolling-wrap {
    gap: 14px;
  }

  .scrolling-wrap .comm {
    gap: 14px;
  }

  .scrolling-wrap .comm .cmn-textslide {
    font-size: 48px;
  }

  .scrolling-wrap .comm img {
    width: 50px;
    object-fit: contain;
  }
}

@media (max-width: 575px) {
  .scrolling-wrap .comm {
    gap: 12px;
  }

  .scrolling-wrap .comm .cmn-textslide {
    font-size: 36px;
  }

  .scrolling-wrap .comm img {
    width: 50px;
    object-fit: contain;
  }
}

.mycustom-marque {
  background-color: var(--color-merkos-primary);
  padding: 0 145px;
}

@media (max-width: 1600px) {
  .mycustom-marque {
    padding: 0 50px;
  }
}

@media (max-width: 1199px) {
  .mycustom-marque {
    padding: 0 30px;
  }
}

.mycustom-marque.style-2 {
  padding: 0;
  background-color: transparent;
  margin-bottom: -20px;
}

.mycustom-marque.style-2 .scrolling-wrap .comm .cmn-textslide {
  font-size: 80px;
  font-weight: 700;
  line-height: 95px;
  text-transform: uppercase;
  color: var(--text);
  letter-spacing: 3.2px;
  font-family: "Kumbh Sans", sans-serif;
}

.mycustom-marque.style-2 .scrolling-wrap .comm .cmn-textslide.text-custom-storke {
  -webkit-text-stroke: 1px var(--text);
  -webkit-text-fill-color: transparent;
}

.mycustom-marque.style-2 .scrolling-wrap:hover .comm {
  animation-play-state: paused;
}

@media (max-width: 1399px) {
  .mycustom-marque.style-2 .scrolling-wrap .comm {
    gap: 20px;
  }
}

@media (max-width: 991px) {
  .mycustom-marque.style-2 .scrolling-wrap {
    gap: 14px;
  }

  .mycustom-marque.style-2 .scrolling-wrap .comm {
    gap: 14px;
  }

  .mycustom-marque.style-2 .scrolling-wrap .comm .cmn-textslide {
    font-size: 48px;
  }

  .mycustom-marque.style-2 .scrolling-wrap .comm img {
    width: 50px;
    object-fit: contain;
  }
}

@media (max-width: 575px) {
  .mycustom-marque.style-2 .scrolling-wrap .comm {
    gap: 12px;
  }

  .mycustom-marque.style-2 .scrolling-wrap .comm .cmn-textslide {
    font-size: 36px;
  }

  .mycustom-marque.style-2 .scrolling-wrap .comm img {
    width: 50px;
    object-fit: contain;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes scroll2 {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-200%);
  }
}

/*# sourceMappingURL=main.css.map */


/* Sidebar styles */
.sidebar {
  position: fixed;
  right: -300px;
  top: 0;
  width: 300px;
  height: 100%;
  background-color: #fff;
  box-shadow: -5px 0 10px rgba(0, 0, 0, 0.3);
  transition: right 0.3s ease;
  z-index: 10;
  border-left: 5px solid var(--color-merkos-primary);
  /* #60ac45 left border */
}

.sidebar.active {
  right: 0;
}

.sidebar-content {
  padding: 20px;
}

.close-sidebar {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--color-merkos-primary);
  /* #60ac45 background */
  color: #fff;
  /* White icon */
  border: none;
  width: 40px;
  /* Increased width */
  height: 40px;
  /* Increased height */
  border-radius: 50%;
  /* Circular button */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.5rem;
  /* Increased font size for 'X' */
  line-height: 1;
  /* Ensure that the 'X' icon is not affected by line spacing */
}

.sidebar-nav ul {
  list-style: none;
  padding: 0;
  margin: 60px 5px;
}

.sidebar-nav ul li {
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 15px;
  /* Space for the divider */
  font-size: 18px;
}

.sidebar-nav ul li::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #ccc;
  /* Divider color */
}

.sidebar-nav ul li a {
  text-decoration: none;
  color: #000;
  /* text-decoration: underline; */
}

/* Search Modal */
.search-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.search-modal-content {
  position: relative;

  padding: 20px;
  width: 90%;
  max-width: 600px;
  border-radius: 8px;
}

.close-search {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;

  color: #2bc7f7;
  /* White icon */
  border: none;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  /* Circular button */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.search-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-input {
  width: 80%;
  padding: 10px;
  font-size: 18px;
  border: 0px;
  border-bottom: 2px solid #2bc7f7;
  border-radius: 5px;
  text-align: center;
  color: #2bc7f7 !important;
  background-color: transparent;
}

.search-submit-btn {
  padding: 10px;
  font-size: 18px;
  background-color: transparent;
  color: red;
  border: none;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
}