.drop-area {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;

  border-radius: 5px;
  height: 50px;
  border: 3px dashed #171c62;
  color: #171c62;
  font-weight: 700;
  margin-bottom: 10px;
}

.questions-title-option {
  font-size: 25px;
  line-height: 25px;
  color: #171c62;
  margin-bottom: 15px;
  font-weight: 500;
}

.questions-options-list-border {
  border: 1px solid #e9e9f3;
  border-radius: 6px;
  margin-bottom: 2rem;
}

.questions-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  height: 55px;
  border-bottom: 1px solid #e9e9f3;
  cursor: pointer;
}

.questions-options:hover {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
}

.icon-options {
  width: 25px;
  height: 55px;
  justify-content: center;
  display: flex;
  align-items: center;
  align-content: center;
  font-weight: 800;
}

.icon-options .icon {
  display: none;
}

.questions-options {
  color: #171c62;
  font-weight: 500;
}

.questions-options:hover .icon-options {
  background: #e9e9f3;
  border-radius: 0px 6px 6px 0px;
}

.questions-options:hover .icon {
  display: block;
}

.form-field-hover {
  background-color: #e9e9f3;
  border: 1px solid #676b98;
  border-radius: 6px;
  margin-bottom: 10px;
}

.visual-control-forms {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.form-field-title {
  font-weight: 500;
  color: #171c62;
}
