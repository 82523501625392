// @use "../styles/abstracts/variables";
@import "abstracts/variables";
@import "components/btn-group", "components/buttons", "components/fileUpload",
"components/slider", "components/grid-editor", "components/questions-builders",
"components/post-preview", 'components/email-builder', 'components/course-player', 'components/ui-utils', 'components/media-gallery',
'components/applet-component', 'components/calendar-dropin', 'components/network-directory', 'components/video-player',
'/server/libs/index.scss', '/server/services/CourseService/frontend/index.scss', 'components/shop-items', 'components/main', 'components/cteen_index',
'components/speech-bubble';

.sticky-top {
  z-index: 1;
}

input.ms-form-control,
textarea.ms-form-control,
select.ms-form-control {
  padding: 8px 16px;
  border: 1px solid #bbbcd3;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  border-radius: 6px;
  background: #ffffff;
}

p {
  margin: 0;
}

input,
select,
textarea {
  &.form-select {
    // width: auto;
  }

  &.form-control {
    // padding: 10.5px 16px;
    border: 1px solid #bbbcd3;
    width: 100%;
    // line-height: 1;
    color: $color-text;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 6px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &.is-valid,
    &:valid {
      border-color: var(--color-merkos-primary);
    }
  }

  &.form-control:focus {
    border-color: var(--color-merkos-primary);
    box-shadow: 0 0 0 0.2rem $ck-color-base-focus;
  }
}

.ms-form-control:focus {
  outline: 1px solid #4f4f5e;
}

.ms-form-title {
  color: var(--color-merkos-primary);
  font-family: "Pluto Sans";
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
}

.ms-form-section-title {
  color: var(--color-merkos-primary);
  font-family: "Pluto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 41px;
  letter-spacing: 0.374px;
  border-bottom: 2px solid #e2e3f0;
  padding: 16px 0px 8px;
}

.grid-focus-cell:focus {
  position: relative;
  white-space: normal;
  border: 1px solid #282086;
  background: white;
  box-shadow: 0 0.5rem 1rem #7696bf2e;
  padding: 5px;
  z-index: 2;
  border-radius: 6px;
  overflow: auto;
}

.grid-focus-cell {
  padding: 4px 3px;
}

.listtable td {
  border: 1px solid #e2e3ee;
}

.listtable tr:hover {
  background: #f8f8f8;
}

.nav-link {
  color: var(--color-merkos-primary);
}

.ui-edit-element .resizer {
  display: none;
}

.ui-edit-element:hover .resizer {
  display: block;
}

.ms-bg {
  background: #fbfbfe;
}

.text-ms {
  color: var(--color-merkos-primary);
}

.ms-card {
  background: #ffffff;
  border: 1px solid #e2e3f0;
  border-radius: 8px;
}

img {
  max-width: 100%;
}

.ms-card-img {
  border-radius: 8px 8px 0 0;

  height: 177px;
}

.ms-card-body {
  padding: 6px;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
.hover-box-scrollbar {
  overflow: auto;
  scrollbar-width: auto;
  scrollbar-color: transparent #ffffff;
}

.hover-box-scrollbar:hover {
  scrollbar-width: auto;
  scrollbar-color: #c3b8c6 #ffffff;
}

/* Chrome, Edge, and Safari */
.hover-box-scrollbar::-webkit-scrollbar {
  width: 16px;
  height: 2px;
}

.hover-box-scrollbar::-webkit-scrollbar-track {
  // background: #ffffff;
}

.hover-box-scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
  transition: background-color 2s;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

.hover-box-scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: #cfcccf;
  opacity: 1;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

/* General scrollbar Chrome, Edge, and Safari */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.scroll-2px::-webkit-scrollbar {
  width: 3px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #e2e3ef;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #282086;
  transition: background-color 2s;
  border-radius: 10px;
  border: 1px solid #e2e3ef;
}

.settings-bg {
  background: #fbfbfe;
}

.settings-card {
  background-color: white;
  border-radius: 7px;
}

.settings-card>.title {
  border-bottom: 1px solid #dfe5eb;
  padding: 16px 24px;
  font-weight: bold;
  font-size: 20px;
}


.padded-content {
  padding: 31px 24px;
}

.ms-input {
  outline: 0;
  border: 1px solid #a2a4c0;
  border-radius: 6px;
  padding: 8px 10px;
  background: transparent;
}

@media (max-width:481px) {

  .ms-input {
    padding: 12px 10px;
  }

}

.ms-input:hover:not(:active):not(:active):not(:focus) {
  background: #e2e3f085;
}

.ckeditor-wrapper .ck-content:hover:not(.ck-focused) {
  background: #e2e3f085;
}

.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover {
  background: #e2e3f085;
}

.ckeditor-wrapper .ck-content {
  padding: 0 10px;
}

.ck.ck-toolbar .ck.ck-toolbar__separator {
  background: #a2a4c0;
  margin-top: 0;
  margin-bottom: 0;
}

.ck.ck-toolbar {
  border-radius: 6px 6px 0 0 !important;
  border: 1px solid #a2a4c0;
  background: transparent !important;
}

.ckeditor-wrapper .ck-content.ck-focused {
  box-shadow: none !important;
  border: 0;
  outline: 0 !important;
  background: 0;
}

/* .hover-scrollbar{
    overflow: hidden;
}
.hover-scrollbar:hover{
    overflow-y: auto;
} */

.ms-table-title {
  color: #a2a4c0;
}

.ckeditor-wrapper {
  outline: 0;
  border: 1px solid #a2a4c0;
  border-radius: 6px;
  background: transparent;
  width: 100%;
}

.ms-input:disabled {
  border-radius: 6px;
  background: #f5f5f8;
  border: 0;
}

.reset-btn {
  outline: 0;
  background: 0;
  border: 0;
}

a {
  color: inherit;
  /* blue colors for links too */
  text-decoration: inherit;
  /* no underline */
}

.title-text {
  color: #000624;
}

.s-btn-hover:hover {
  background-color: #e7f0ff;
}

.s-btn-hover.active {
  background-color: #116dff;
  color: white;
}

input[type="range"]::-webkit-slider-runnable-track {
  background: #e8f1ff;
  border-radius: 14px;
}

// .tooltip {
//   position: absolute;
//   pointer-events: none;
//   right: -1em;
//   padding: 0.5em 0.75em;

//   background-color: #351c75;
//   color: #fff;
//   border-radius: 4px;

//   line-height: 1;
//   white-space: nowrap;

//   display: none;
// }

.tooltip-below {
  top: calc(100% + 0.76em);
}

.tooltip-below::before {
  content: "";
  position: absolute;
  bottom: 100%;
  right: 1.1em;

  width: 0;
  height: 0;

  border-style: solid;
  border-width: 6px 4px 0 4px;
  transform: rotate(180deg);
  border-color: #351c75 transparent transparent transparent;
}

.tooltip-above {
  bottom: calc(100% + 0.76em);
}

.tooltip-above::before {
  content: "";
  position: absolute;
  top: 100%;
  right: 1.1em;

  width: 0;
  height: 0;

  border-style: solid;
  border-width: 6px 4px 0 4px;
  border-color: #351c75 transparent transparent transparent;
}

// .tooltip-trigger:hover .tooltip {
//   display: block;
// }

/* Tooltip container */
// .tooltip {
//   position: relative;
//   display: inline-block;
//   border-bottom: 1px dotted black;
//   /* If you want dots under the hoverable text */
// }

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

#SITE_CONTAINER,
#SITE_CONTAINER {
  position: relative;
  overflow: auto;
}

.CONTENT_AREA {
  width: 950px;
  margin: 0 auto;
  position: relative;
  height: 100%;
}

.mobile-mode .CONTENT_AREA {
  width: 320px;
  margin: 0 auto;
  position: relative;
}

#SITE_CONTAINER .active-elm:not(.not-active-elm) {
  outline: 1px solid #116dff;
}

#SITE_CONTAINER .not-active-elm:hover>.offerElementButton {
  display: block !important;
}

.resizer:hover {
  box-shadow: 0 0.5rem 1rem #116cff93;
}

.resizer {
  background: #116dff;
}

#SITE_CONTAINER .active-elm:not(.not-active-elm) .tag {
  background-color: #116dff;
  color: white;
  display: block !important;
}

[contenteditable="true"]:active,
[contenteditable="true"]:focus {
  border: none;
  outline: none;
}

#SITE_CONTAINER .hover-elm:not(.active-elm) .tag {
  background-color: #e8f1ff;
  color: #116dff;
  display: block !important;
}

#SITE_CONTAINER .hover-elm:not(.active-elm) {
  outline: 1px solid #e8f1ff;
  background: rgba(212, 212, 212, 0.404);
}

#PAGE_GRIDS {
  border-left: 2px dashed #ccc;
  border-right: 2px dashed #ccc;
  width: 950px;
  margin: 0 auto;
}

.mobile-mode #PAGE_GRIDS {
  width: 320px;
  margin: 0 auto;
}

#SITE_HEADER {
  position: relative;
  border-bottom: 2px dashed #ccc;
}

#PAGE_GRIDS_WRAPPER {
  position: absolute;
  pointer-events: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#zmmtg-root {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -2;
  top: 0;
  left: 0;
  background-color: black;
}

.hover-bg-grey:disabled {
  color: #37373854;
}

.hover-bg-grey:hover:not(:disabled) {
  background: #d6e6fe;
  color: #116dff;
  fill: #116dff;
}

.soft-btn {
  border: 0;
  background: none;
}

.soft-btn.active,
.soft-btn:active,
.soft-btn:hover {
  border: 0;
  background: #c3daff;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-inline-center {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hover-bg-grey.active,
.hover-bg-grey:active {
  background: #116dff;
  color: white;
  fill: white;
}

.list-item-selectable:hover {
  background: #c7caf0;
}

.list-item-selectable {
  display: block;
  text-align: left;
  border: 0;
  background: 0;
  margin: 0.25em 0;
  padding: 0.7em 1em;
  width: 100%;
}

.std-navbar li {
  transition: background cubic-bezier(0.075, 0.82, 0.165, 1);
  display: flex;
  align-items: center;
  position: relative;
  font-size: 18px;
}

.std-navbar li a {
  padding: 1em;
  display: inline-block;

  color: var(--color-merkos-primary);
  text-decoration: none;
}


.std-navbar li:hover {
  // background: #e2e3f0;
  color: var(--color-merkos-primary);
}

.std-navbar li a.active div {
  gap: 28px;
  margin-left: 8px
}

.std-navbar li a.active {
  color: var(--color-merkos-primary);
  border-radius: 3px;
  font-weight: bold;
}

.std-navbar:not(.horizontal-navbar) li .active::before,
.active-carret::before {
  content: "";
  width: 4px;
  height: 2em;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1px;
  display: inline-block;
  border-radius: 0 7px 7px 0;
  background: var(--color-merkos-primary);
}

.std-navbar.horizontal-navbar li .active::before {
  content: "";
  height: 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: inline-block;
  border-radius: 8px 8px 0 0;
  background: var(--color-merkos-primary);
}

.table-div {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 6px;
}

.table-div .input-table-search .input-group-text {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
}

.table-div .input-table-search .form-control {
  border: none;
  padding: 10.5px 0;
}

.table-div .input-table-search .form-control:focus {
  box-shadow: none;
}

.mytable {
  border-collapse: collapse;
  border-style: hidden;
  width: 100%;
  // min-width: max-content;
}

.mytable tr td {
  color: #171c62;
  font-weight: 400;
}

.mytable tr:last-child td:first-child {
  border-bottom: 1px solid #e2e3f0;
  border-left: 1px solid #e2e3f0;
  border-bottom-left-radius: 6px;
}

.mytable tr:last-child td:last-child {
  border-bottom: 1px solid #e2e3f0;
  border-right: 1px solid #e2e3f0;
  border-bottom-right-radius: 6px;
}

.mytable thead tr:first-child> :first-child {
  border-top: 1px solid #e2e3f0;
  border-left: 1px solid #e2e3f0;
  border-top-left-radius: 6px;
}

.mytable thead tr:first-child> :last-child {
  border-right: 1px solid #e2e3f0;
  border-top: 1px solid #e2e3f0;
  border-top-right-radius: 6px;
}

.mytable tbody td {
  padding: 0.75rem;
  color: var(--color-merkos-primary);
  background: white;
  border-bottom: 1px solid #e2e3f0;
  border-right: 0;
  border-left: 0;
}

.mytable thead th {
  padding: 0.75rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.6875rem;
  vertical-align: bottom;
  color: #a2a4c0;
  background-color: #f5f5f8;
}

.mytable tr:hover td {
  background-color: #d6e6f7;
}

.animate-in {
  background-image: linear-gradient(45deg,
      #5a95d6,
      #82acda,
      rgb(127, 126, 167)) !important;
  border: 0;
  background-size: 300% !important;
  background-position: left;
}

.animate-in:hover,
.animate-in:focus {
  background-position: right;
  transition: background-position 0.6s;
}

.animate-in {
  animation: animate-in forwards;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.animate-in-left {
  border: 0;
  background-size: 300% !important;
  background-position: left;
}

.animate-in-left:hover,
.animate-in-left:focus {
  background-position: right;
  transition: background-position 0.6s;
}

.animate-in-left {
  animation: animate-in-left forwards;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.anim-new-points {
  animation: anim-new-points forwards;
  animation-duration: 0.4s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.anim-old-points {
  animation: anim-old-points forwards;
  animation-duration: 0.4s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

@keyframes anim-new-points {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes anim-old-points {
  0% {
    transform: translateY(0%);
    opacity: 1;
  }

  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}

@keyframes logo {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes logo-sub {
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes animate-in {
  0% {
    display: block !important;
    transform: scale(0) translateX(-20px);
    opacity: 0;
    transform-origin: center;
  }

  100% {
    transform: scale(1) translateX(0px);
    opacity: 1;
  }
}

@keyframes animate-in-left {
  0% {
    display: block !important;
    transform: scale(0);
    opacity: 0;
    transform-origin: left;
  }

  100% {
    transform: scale(1) translateX(0px);
    opacity: 1;
  }
}

.pyro-wrapper {
  margin: 0;
  padding: 0;
  /* background: white; */
  overflow: hidden;
  position: fixed;
}

.pyro>.before,
.pyro>.after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: -120px -218.66667px blue, 248px -16.66667px #00ff84,
    190px 16.33333px #002bff, -113px -308.66667px #ff009d,
    -109px -287.66667px #ffb300, -50px -313.66667px #ff006e,
    226px -31.66667px #ff4000, 180px -351.66667px #ff00d0,
    -12px -338.66667px #00f6ff, 220px -388.66667px #99ff00,
    -69px -27.66667px #ff0400, -111px -339.66667px #6200ff,
    155px -237.66667px #00ddff, -152px -380.66667px #00ffd0,
    -50px -37.66667px #00ffdd, -95px -175.66667px #a6ff00,
    -88px 10.33333px #0d00ff, 112px -309.66667px #005eff,
    69px -415.66667px #ff00a6, 168px -100.66667px #ff004c,
    -244px 24.33333px #ff6600, 97px -325.66667px #ff0066,
    -211px -182.66667px #00ffa2, 236px -126.66667px #b700ff,
    140px -196.66667px #9000ff, 125px -175.66667px #00bbff,
    118px -381.66667px #ff002f, 144px -111.66667px #ffae00,
    36px -78.66667px #f600ff, -63px -196.66667px #c800ff,
    -218px -227.66667px #d4ff00, -134px -377.66667px #ea00ff,
    -36px -412.66667px #ff00d4, 209px -106.66667px #00fff2,
    91px -278.66667px #000dff, -22px -191.66667px #9dff00,
    139px -392.66667px #a6ff00, 56px -2.66667px #0099ff,
    -156px -276.66667px #ea00ff, -163px -233.66667px #00fffb,
    -238px -346.66667px #00ff73, 62px -363.66667px #0088ff,
    244px -170.66667px #0062ff, 224px -142.66667px #b300ff,
    141px -208.66667px #9000ff, 211px -285.66667px #ff6600,
    181px -128.66667px #1e00ff, 90px -123.66667px #c800ff,
    189px 70.33333px #00ffc8, -18px -383.66667px #00ff33,
    100px -6.66667px #ff008c;
  -moz-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -webkit-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -o-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -ms-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
}

.pyro>.after {
  -moz-animation-delay: 1.25s, 1.25s, 1.25s;
  -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
  -o-animation-delay: 1.25s, 1.25s, 1.25s;
  -ms-animation-delay: 1.25s, 1.25s, 1.25s;
  animation-delay: 1.25s, 1.25s, 1.25s;
  -moz-animation-duration: 1.25s, 1.25s, 6.25s;
  -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
  -o-animation-duration: 1.25s, 1.25s, 6.25s;
  -ms-animation-duration: 1.25s, 1.25s, 6.25s;
  animation-duration: 1.25s, 1.25s, 6.25s;
}

.pyro-wrapper {
  margin: 0;
  padding: 0;
  /* background: white; */
  overflow: hidden;
}

.pyro>.before,
.pyro>.after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: -120px -218.66667px blue, 248px -16.66667px #00ff84,
    190px 16.33333px #002bff, -113px -308.66667px #ff009d,
    -109px -287.66667px #ffb300, -50px -313.66667px #ff006e,
    226px -31.66667px #ff4000, 180px -351.66667px #ff00d0,
    -12px -338.66667px #00f6ff, 220px -388.66667px #99ff00,
    -69px -27.66667px #ff0400, -111px -339.66667px #6200ff,
    155px -237.66667px #00ddff, -152px -380.66667px #00ffd0,
    -50px -37.66667px #00ffdd, -95px -175.66667px #a6ff00,
    -88px 10.33333px #0d00ff, 112px -309.66667px #005eff,
    69px -415.66667px #ff00a6, 168px -100.66667px #ff004c,
    -244px 24.33333px #ff6600, 97px -325.66667px #ff0066,
    -211px -182.66667px #00ffa2, 236px -126.66667px #b700ff,
    140px -196.66667px #9000ff, 125px -175.66667px #00bbff,
    118px -381.66667px #ff002f, 144px -111.66667px #ffae00,
    36px -78.66667px #f600ff, -63px -196.66667px #c800ff,
    -218px -227.66667px #d4ff00, -134px -377.66667px #ea00ff,
    -36px -412.66667px #ff00d4, 209px -106.66667px #00fff2,
    91px -278.66667px #000dff, -22px -191.66667px #9dff00,
    139px -392.66667px #a6ff00, 56px -2.66667px #0099ff,
    -156px -276.66667px #ea00ff, -163px -233.66667px #00fffb,
    -238px -346.66667px #00ff73, 62px -363.66667px #0088ff,
    244px -170.66667px #0062ff, 224px -142.66667px #b300ff,
    141px -208.66667px #9000ff, 211px -285.66667px #ff6600,
    181px -128.66667px #1e00ff, 90px -123.66667px #c800ff,
    189px 70.33333px #00ffc8, -18px -383.66667px #00ff33,
    100px -6.66667px #ff008c;
  -moz-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -webkit-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -o-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -ms-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
}

.pyro>.after {
  -moz-animation-delay: 1.25s, 1.25s, 1.25s;
  -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
  -o-animation-delay: 1.25s, 1.25s, 1.25s;
  -ms-animation-delay: 1.25s, 1.25s, 1.25s;
  animation-delay: 1.25s, 1.25s, 1.25s;
  -moz-animation-duration: 1.25s, 1.25s, 6.25s;
  -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
  -o-animation-duration: 1.25s, 1.25s, 6.25s;
  -ms-animation-duration: 1.25s, 1.25s, 6.25s;
  animation-duration: 1.25s, 1.25s, 6.25s;
}

@-webkit-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white;
  }
}

@-moz-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white;
  }
}

@-o-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white;
  }
}

@-ms-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white;
  }
}

@keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white;
  }
}

@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}

@-moz-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}

@-o-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}

@-ms-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}

@keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}

@-webkit-keyframes position {

  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

@-moz-keyframes position {

  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

@-o-keyframes position {

  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

@-ms-keyframes position {

  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

@keyframes position {

  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

.wheelOfFortune {
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.wheel {
  display: block;
}

.wheel-spinner {
  /* font-size: 1.5em; */
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30%;
  height: 30%;
  margin: -15%;
  background: #fff;
  color: #fff;
  box-shadow: 0 0 0 8px currentColor, 0 0px 15px 5px rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  transition: 0.8s;
}

.wheel-spinner::after {
  content: "";
  position: absolute;
  top: -17px;
  border: 10px solid transparent;
  border-bottom-color: currentColor;
  border-top: none;
}

.hover-shadow:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

/* Sites */
.ms-border-t {
  border-top: 1px solid #dfe5eb;
}

.ms-border {
  border: 1px solid #dfe5eb;
}

.ms-border-l {
  border-left: 1px solid #dfe5eb;
}

.ms-border-r {
  border-right: 1px solid #dfe5eb;
}

.ms-border-b {
  border-bottom: 1px solid #dfe5eb;
}

.simple-hover-state-container:hover>.simple-hover-state-element {
  display: block;
}

.simple-hover-state-container>.simple-hover-state-element {
  display: none;
}

.simple-hover-state-vis-container:hover .simple-hover-state-vis-element {
  visibility: visible;
}

.simple-hover-state-vis-container .simple-hover-state-vis-element {
  visibility: hidden;
}

.ui-edit-element:hover .editor-elm-resizer {
  background: #e9d5b0;
  border: 2px solid #e9d5b0;
}

.ui-edit-element .editor-elm-resizer {
  background: none;
  border: 2px solid transparent;
}

.hover-state-container {
  position: relative;
}

.hover-state-container>.hover-state-element {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.hover-state-container:hover>.hover-state-element {
  opacity: 1;
}

@media print {
  .pagebreak {
    page-break-after: always;
  }

  .no-print, .no-print *
  {
      display: none !important;
  }
}

/* ELEMENTS TRAY */
.editor-left-pane {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 400px;
}

.site-pane-item {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  transition: background-color .15s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tplExpr {
  background: #21a9fd;
  border-radius: 6px;
  padding: 3px 0px;
  display: inline-block;
  margin: 2px
}

.site-pane-item.active:not(.btn-primary) {
  background-color: #e7f1ff
}

.site-pane-item:hover:not(.btn-primary) {
  background-color: #e7f1ff
}

.elements-tray-selector {
  display: flex;
  flex-grow: 1;
  background-color: #ffffff;
  margin-top: 10px;
  max-width: 100%;
  overflow: hidden;
}

.elements-tray-selector__categories {
  display: grid;
  grid-template-columns: repeat(3, minmax(110px, 1fr));
  grid-template-rows: repeat(auto-fill, 100px);
  grid-gap: 9px;
  padding-inline: 30px;
  overflow-y: auto;
}

.elements-tray-selector__components {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: auto;
}

.elements-tray-selector__categories__item {
  overflow: hidden;
  background-color: #fbfbfe;
  border: 1px solid #e2e3f0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  transition: all 0.5s;
}

.elements-tray-selector__categories__item:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.elements-tray-selector__categories__item__img {
  width: 20px;
}

.elements-tray-selector__categories__item__text {
  display: block;
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  line-height: 15px;
  color: #1c1f48;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

div:hover>.parent-hover-bg {
  background-color: #e7f1ff;
  outline: 1px solid #647388;
}

.mytable thead th {
  position: sticky;
  top: 0;
}

// extension to bootstrap's
.table-responsive {
  height: 100%;

  scrollbar-width: thin;
  overflow-x: visible !important;
}

/* ELEMENTS TRAY */

@import "../styles/components/slider";
@import "../styles/components/fileUpload";