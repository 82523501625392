.email-builder {
  .block-segment{
    border:1px solid transparent;
    &:hover:not(.active){
      border:1px solid var(--decoration-color);
    }
    &.active{
      border:1px solid #0f1d94;
    }
  }
  .section {
    // border-top: 1px solid white;
    // border-bottom: 1px solid white;
    .empty-block-column{
      height: 70px;;
      border-radius: 8px;
      border:2px inset  var(--decoration-color);
      padding:7px;
      display: flex;
      align-items: center;
      text-align: center;
    }
    .section-menu-btn {
      display: none;
      background:green;
      border-radius: 50%;
      width:24px;
      height:24px;
      border:none;
      color:white;
      align-items: center;
      justify-content: center;
     
    }

    &:hover {
      .section-menu-btn {
        display: inline-flex;
      }

      border-top: 1px solid rgb(37, 150, 140);
      border-bottom: 1px solid rgb(37, 150, 140);
    }
  }
}