.mon-picker {
    width: max-content;
    position: fixed;
    background-color: #ffffff;
}

.curr-date {
    padding: 3px;
    border-radius: 5px;
    background-color: #2F80ED;
    color: #ffffff;
}

.highlight-date {
    padding: 2px 4px;
    padding: #6949FF26;
    border-radius: 17px;
    color: #6949FF;

}

.calendar-table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
}

.calendar-table td{
    padding:4px 2px;
}

.highlight-date-td {
    border: 1px solid #E9E8E8;
    border-radius: 4px;
}

.day-selector {
    border-radius: 8px;
}

.day-selector {
    background: none;
    font-size: 12px;
    color:inherit;
    transition: all 0.2s ease-in-out;
    display: inline-block;
}

// .day-selector:hover,
.day-selector-active {

    padding: 2px 4px;
    background: #6949FF26;
    border-radius: 17px;
    color: #6949FF;
}

.prev-post-days {
    background-color: #DCDDE7;
}


.triangle-down {
    position: relative;
    top: -5px;
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    border-right: 0.2em solid black;
    border-top: 0.2em solid black;
    transform: rotate(135deg);
    margin-right: 0.5em;
    margin-left: 1.0em;
}

//re-write the above style with margins and borders to utilize flexbox
.events-sidebar {
    background-color: #ffffff;
    border: 1px solid #E9E9F3;
    border-radius: 4px;
    margin-bottom: 1em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1em;
    border: 1px solid #E9E9F3;
    overflow: scroll;
}


//write a style events-navbar to utilize flexbox and have similar spacing to .event-row, with a border-bottom
.events-navbar {
    position: sticky;
    top: 0;
    z-index: 1020;
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding: 1em;
    margin-bottom: 1em;
    border-bottom: 1px solid #676B98;
    background-color: #ffffff;
}

.event-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 1em;
    padding-right: 1em;
    margin-bottom: 1em;
    border-bottom: 1px solid #E9E9F3;
}