/* ===== button.btn CSS ===== */

.btn {
  border-radius: 0.5rem;
  font-weight: 400;
}

.btn-primary,
.btn-primary.active,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:disabled,
.btn-primary.disabled,
// .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary:active,
.btn-outline-primary:hover,
.btn-outline-primary:active {
  color: $color-white;
  background-color: var(--color-merkos-primary);
  border-color: var(--color-merkos-primary);
}

.btn-outline-primary,
.btn-outline-primary:disabled,
.btn-outline-primary.disabled {
  color: var(--color-merkos-primary);
  background: transparent;
  border: 1px solid var(--color-merkos-primary);
}

.btn-link,
.btn-link.active,
.btn-link:active,
.btn-link:focus,
.btn-link:hover,
.btn-link:active,
.btn-link:disabled,
.btn-link.disabled {
  color: var(--color-merkos-primary);
  text-decoration: none;
}

.btn-primary:disabled,
.btn-primary.disabled,
.btn-outline-primary:disabled,
.btn-outline-primary.disabled,
.btn-link:disabled,
.btn-link.disabled {
  opacity: 0.65;
}

.btn-check:focus+.btn,
.btn:focus {
  // box-shadow: none;
}

.list-group-item.active,
.nav-pills .nav-link.active,
.nav-pills .show>.nav-link,
.nav-pills .show>.nav-link,
.nav-pills .nav-link.active,
.list-group-item.active {
  color: var(--color-merkos-primary);
  font-weight: bold;
  background: $color-white;
  border: 1px solid var(--color-merkos-primary);
  border-radius: 0.5rem;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  transition: shadow 0.5s;
}

.list-group2-item {
  background:none;
  border:0;
  color: #171C62;
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 1.25rem;
  display:block;
  width:100%;
}

.list-group2-item:hover { 
  background: rgba(47, 128, 237, 0.10);
}

.m-btn-secondary:hover {
  background: #f5f9fe;
  color: #2F80ED;
}