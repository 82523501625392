.photo-left-slider {
    // media query for mobile  display as flex column
    @media (max-width: 768px) {
        display: flex;
        flex-wrap: wrap-reverse;
        // child divs display minimum width 100%
        > div {
            width: 100%;
        }
        
    }
    .photo-left-slider-pills {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-bottom: 12px;
        @media screen and (max-width: 768px) {
            flex-direction: row;
            gap: 0;
            padding: 0 0 24px;
            
        }
    }
}