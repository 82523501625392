.course-action-button {

  // default style
  &,
  &:active,
  &:focus {
    background-color: #2f81ed;
    color: var(--color-merkos-1);
  }

  // hover/keyboard-focus style
  &:hover,
  &:focus-visible {
    background-color: var(--color-merkos-primary) !important;
    color: white;
  }
}

.CoursePlayer__Resource {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e9e9f3;
  border-radius: 16px;
  padding: 16px 24px;
}


.coursePlayer__QuickNavBar {
  overflow: hidden;
  position: absolute;
  bottom: 0;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  border: 1px solid #e9e9f3;
}

.coursePlayer__QuickNavButtonOutline {
  border: 1px solid #e9e9f3;
  border-radius: 8px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.coursePlayer__QuickNavButton {
  border: 1px solid #e9e9f3;
  border-radius: 8px;
  padding: 16px;
  display: inline-flex;
  justify-content: space-between;
  height: 48px;
  width: 48px;
}
coursePlayer__QuickNavButtonIcon {
  transition: color .15s ease-in-out;
}

.coursePlayer__QuickNavButtonIcon:not(:hover) {
  color: var(--color-merkos-4);
}

.CoursePlayer__Presenters {
  display: grid;
  gap: 1.25rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}
