.network-directory {
    min-height: 100vh;
    font-family: "Trebuchet MS";
    line-height: 26px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        font-family: "Trebuchet MS";
    }

    a,
    a:visited {
        color: #41b6e6;
    }

    a:hover {
        color: #3399cc;
    }

    .card {
        height: 100%;
        border-radius: 6px;
        padding-bottom: 55px !important;
        min-width: 250px;

        span.network-name {
            background: #eee !important;
        }

        .modal-only,
        .contact-info {
            display: none;
        }

        div.button-wrapper {
            position: absolute;
            border: none;
            bottom: 12px;
            width: 100%;
            text-align: center;
            margin-left: -16px;
            padding: 0 16px;

            button {
                background: #41b6e6;
                border-color: #41b6e6;
            }

            button:hover {
                background: #3399cc;
            }
        }
    }

    footer.directory-footer {
        background: rgba(90, 90, 90, 0.1);
    }

    #modalContent button,
    #modalContent .no-modal {
        display: none;
    }

    #modalContent {
        .smalltext {
            font-size: 0.75rem;
        }

        .modal-only {
            display: block;
        }
    }

    .btn-light {
        border: 1px solid #ddd;
    }
}