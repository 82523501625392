.course-video{
    &:hover{
        background-color: #b7b4b4;
    }
    &.enabled{
        cursor: pointer;
        
    }
    &.disabled{
        opacity:.5
    }
}
.course-active-video{
    background:#e3e1e1;
}

.course-player-section{
    cursor:pointer;
}