.speech-bubble {
	position: relative;
	background: #ff7e4c;
    color: #fff;
	// border-radius: .4em;
}

.speech-bubble-right:after {
	content: '';
	position: absolute;
	right: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 20px solid transparent;
	border-left-color: #ff7e4c;
	border-right: 0;
	border-top: 0;
	margin-top: -10px;
	margin-right: -20px;
}


.speech-bubble-left:after {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 20px solid transparent;
	border-right-color: #ff7e4c;
	border-left: 0;
	border-top: 0;
	margin-top: -10px;
	margin-left: -20px;
}