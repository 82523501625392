.grid-editor-elm>.grid-editor-title {
    display: none;

}

.grid-editor-elm:hover:not(.active)>.grid-editor-title {
    display: block;
}

.grid-editor-elm.active,
.ge-active-border,
.grid-editor-elm:hover {
    outline: 2px solid #007acc;
}

.grid-editor-elm {
    cursor: move;
}

.grid-editor-resize-btn {
    display: none;
    width: 15px;
    height: 15px;
    border: 2px solid #007acc;
    background-color: white;
    ;
}

.grid-editor-elm.active .grid-editor-resize-btn {
    display: block;
}


// container
.ui-container-builder:hover {
    outline: 2px solid #2F80ED;
}

.ui-container-builder:hover>div{
    outline: 2px solid grey;
    opacity: 0.8;
    background: repeating-linear-gradient(45deg, #b3b3b6, #b3b3b6 5px, #e5e5f7 5px, #e5e5f7 25px);
}


.ui-drop-indicator-horizontal-line,
.ui-drop-indicator-vertical-line,
.ui-drop-indicator-box{
    transition: all 0.2s ease-in-out;
    position: fixed;
    display: block;
    pointer-events: none;
    z-index: 9999;
    background: rgba(47,128,237,0.4);
}

.ui-drop-indicator-horizontal-line {
    background: linear-gradient(180deg,
        rgba(255,255,255,0) 0%,
        rgba(47,128,237,0.4) 44%,
        rgba(47,128,237,0.7) 48%,
        rgba(47,128,237,0.7) 52%,
        rgba(47,128,237,0.4) 56%,
        rgba(255,255,255,0) 100%
    );

}
.ui-drop-indicator-vertical-line {
    background: linear-gradient(90deg,
        rgba(255,255,255,0) 0%,
        rgba(47,128,237,0.4) 44%,
        rgba(47,128,237,0.7) 48%,
        rgba(47,128,237,0.7) 52%,
        rgba(47,128,237,0.4) 56%,
        rgba(255,255,255,0) 100%
    );
}

.ui-drop-indicator-box {
    box-shadow: inset 0 0 12px 12px rgba(47,128,237,0.7);
}
