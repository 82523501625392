.media-pill {
    border-radius: 8px;
    width: 200px;
    height: 200px;
    border: 1px solid $primary-color-btn-outline;
}

.media-pill-text {
    background: rgba(255, 255, 255, .7);
    padding:7px;
    text-align: center;
    border-radius: 8px;
}