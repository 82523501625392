bottom-carret

.merkos-slider.nav.nav-tabs {

  .merkos-slider__badge {
    background-color: #ECECF4;
    color: #A2A4C0;
    font-size: 12px;
    line-height: 16px;
    border-radius: 10px;
    height: 18px;
    padding-inline: 8px;
  }

  .nav-item:not(:last-child) {
    margin-right: 25px;
  }

  .nav-link {
    display: flex;
    gap: 5px;
    justify-content: center;
    border: none;
    cursor: pointer;
    color: #A2A4C0;
    align-items: center;
    padding-inline: 5px;
    padding-bottom: 14px;
    font-size: 17px;
    line-height: 20px;

    &.active {
      position: relative;
      color: #171C62;

      &::after, .bottom-carret::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 4px;
        bottom: 0;
        left: 0;
        background-color: #2F80ED;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }
    }
  }
}

.bottom-carret::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 4px;
  bottom: 0;
  left: 0;
  background-color: #2F80ED;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.left-carret::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 4px;
  bottom: 0;
  left: 0;
  background-color: #2F80ED;
}