.video-player {
  // width: 95%;
  max-width: 750px;
  position: relative;
  //font-size: 0;
  overflow: hidden;

  &:hover {
    overflow: visible;

    .controls {
      height: 54px;
    }

    .controls__button__skip {
      opacity: 1;
    }
  }

  .video {
    width: 100%;
  }

  .controls {
    display: flex;
    position: absolute;
    bottom: 5px;
    width: 100%;
    flex-wrap: wrap;
    background: rgba(0, 0, 0, 0.5);
    transform: translateY(0);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-items: center;
    height: 0px;
    transition: height 0.5s;
  }

  .controls>* {
    flex: 1;
  }

  .progress {
    flex: 10;
    position: relative;
    display: flex;
    flex-basis: 100%;
    height: 10px;
    /* background: rgba(0,0,0,0.5); */
    background: rgba(255, 255, 255, 0.5);
    cursor: pointer;
  }

  .progress__filled {
    width: 50%;
    background: white;
    flex: 0;
    flex-basis: 0%;
  }

  .controls__button {
    background: none;
    border: 0;
    line-height: 1;
    color: white;
    text-align: center;
    outline: 0;
    padding: 1px;
    cursor: pointer;
    max-width: 75px;
  }

  .controls__button__skip {
    position: absolute;
    top: 50%;
    opacity: 0;
    transition: opacity .5s;
    background-color: #3c3c3c41;
    border-radius: 50%;
    padding: 10px;
    transform: translate(0, -50%);
    line-height: 0;

    &:hover,
    &:focus-visible {
      opacity: 1;
    }
  }

  .controls__button__skip[data-skip="-15"] {
    left: 10px;
  }

  .controls__button__skip[data-skip="15"] {
    right: 10px;
  }

  .timeDisplay {
    font-size: 16px;
    max-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }

  .controls__slider {
    width: 10px;
    height: 26px;
  }

  input[name=volume] {
    max-width: 50px;
  }

  input[type=range] {
    background: transparent;
    width: 100%;
    margin: 0 5px;
  }

  input[type=range]:focus {
    outline: none;
  }

  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 4px;
  }

  input[type=range]::-webkit-slider-thumb {
    height: 0.9rem;
    width: 0.9rem;
    border-radius: 50px;
    background: white;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -3.5px;
  }

  input[type=range]::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 4px;
  }

  input[type=range]::-moz-range-thumb {
    height: 0.9rem;
    width: 0.9rem;
    border-radius: 50px;
    background: white;
    cursor: pointer;
    margin-top: -3.5px;
  }

  .controls__select {
    background: none;
    border: 0;
    line-height: 1;
    color: white;
    text-align: center;
    outline: 0;
    padding: 0;
    cursor: pointer;
    max-width: 75px;
  }

  .controls__select option {
    background: black;
    color: white;
  }
}