.AudioPlayer {
    display: grid;
    gap: 20px;
    padding: 40px 20px 30px 20px;
    border: 2px solid #e9e9f3;
    background-color: #fbfbfe;
    grid-template-areas:
        "progress"
        "main-controls"
        "speed";
}

.AudioPlayer__main-controls {
    grid-area: main-controls;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.AudioPlayer__forward-15,
.AudioPlayer__rewind-15,
.AudioPlayer__mute {

    &:hover,
    &:focus-visible {
        background-color: var(--color-merkos-5) !important;
    }
}

.AudioPlayer__speed {
    grid-area: speed;
}

.AudioPlayer__speed-input {
    appearance: none;
    border: 2px solid #d4d5e5;
    background-color: #e9e9f3;
}

.AudioPlayer__progress {
    grid-area: progress;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

input[type=range].AudioPlayer__progress-bar::-webkit-slider-runnable-track,
input[type=range].AudioPlayer__volume-range::-webkit-slider-runnable-track {
    background: initial;
    border-radius: initial;
}

.AudioPlayer__progress-time {
    display: flex;
}

.AudioPlayer__volume {
    display: flex;
}

.AudioPlayer__volume-range {
    grid-area: volume;
}

@media screen and (min-width: 950px) {
    .device-mobile-only {
        display: none;
    }

    .AudioPlayer {
        display: flex;
        gap: 16px;
        padding: 30px 20px;
    }

    .AudioPlayer__progress {
        flex-direction: row;
    }

    .AudioPlayer__main-controls {
        display: contents;
    }

    .AudioPlayer__forward-15,
    .AudioPlayer__play-pause,
    .AudioPlayer__rewind-15 {
        margin: -8px !important;
    }

    .AudioPlayer__mute {
        margin-left: -8px !important;
    }
}

@media screen and (max-width: 949.9px) {
    .device-desktop-only {
        display: none;
    }

    .AudioPlayer {
        justify-items: center;
    }
}