/* ===== file upload component CSS ===== */
$primary-text: #171c62;
$primary-gray: #20272526;

.form-label.upload {
  width: 100px;
  height: 100px;
}
.form-file-upload {
  width: 100%;
  height: 100%;
  outline: none;
  display: none;
}
/*placeholderUploadFile*/
.file-upload {
  width: 100%;
  background-color: $color-white;
  border-radius: 6px;
  border: 1px dashed $primary-color-btn-outline;
  padding: 0;
  cursor: pointer;
  .image-background {
    width: 44px;
    height: 44px;
    background-color: $primary-gray;
    border-radius: 50%;
    margin-top: 16px;
    position: relative;
  }
  .image-avatar {
    width: initial;
    height: initial;
    padding: 10px;
  }
  .text-upload-container {
    .upload-text {
      color: $primary-text;
      text-align: center;
    }
    .upload-disclaimer {
      color: $primary-color-btn-outline;
      text-align: center;
    }
  }
  &::after {
    display: none;
  }
  &.file-uploading {
    border-color: var(--color-merkos-primary);
  }
  &.file-nofile {
    border-color: $primary-color-btn-outline;
  }
}
