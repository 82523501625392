.applet-header-wrapper {
    padding: 55px;
    padding-bottom: 40px;
}
.applet-body-wrapper {
    padding: 55px;
    padding-top:0px;
    @media (max-width: 450px) {
        padding:0px;
    }
}