.value-title {
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 0.07px;
    text-transform: uppercase;
    color: var(--color-merkos-2);
}

.bg-primary-onhover:hover {
    background: var(--color-merkos-3);
}

.m-bg-white {
    background: white;
}

.round-4px {
    border-radius: 4px;
}

.round-6px {
    border-radius: 6px;
}

.round-8px {
    border-radius: 8px;
}

.round-10px {
    border-radius: 10px;
}

.round-12px {
    border-radius: 12px;
}

.round-16px {
    border-radius: 16px;
}

.bg-1 {
    background: var(--color-merkos-1);
}

.bg-1-hover:hover {
    background: var(--color-merkos-1);
}

.bg-2 {
    background: var(--color-merkos-2);
}

.bg-3 {
    background: var(--color-merkos-3);
}

.bg-3-hover:hover {
    background: var(--color-merkos-3) !important;
}

.bg-4 {
    background: #F6F5F4;
}

.bg-4-hover:hover {
    background: #F6F5F4 !important;
}


.hover-bg-primary:hover {
    background: var(--color-merkos-1);
}

.hover-bg-secondary:hover {
    background: var(--color-merkos-2);
}

.bg-secondary-1,
.bg-decoration {
    background: var(--color-merkos-5);
}

.hover-bg-secondary-1:hover {
    background: var(--color-merkos-5) !important;
}

.m-border-1 {
    border: 1px solid var(--color-merkos-5);
}

.m-border-round-1 {
    border-radius: 12px;
    border: 1px solid #E9E9F3;
}

.focus-outline-1:focus-visible {
    outline: 1px solid #E9E9F3;
}

.m-border-primary-hover:hover {
    border: 1px solid var(--color-merkos-1);
}

.m-border-secondary-hover:hover {
    border: 1px solid var(--color-merkos-2);
}

.m-border-secondary-1 {
    border: 1px solid var(--color-merkos-2);
}

.m-border-dropped-1 {
    border-width: 1px 1px 3px;
    border-style: solid;
    border-color: rgb(233, 232, 232);
}

.m-border-2 {
    border: 2px solid var(--color-merkos-5);
}

.m-border-3 {
    border: 3px solid var(--color-merkos-5);
}

.m-border-1-t {
    border-top: 1px solid var(--color-merkos-5);
}

.m-border-1-r {
    border-right: 1px solid var(--color-merkos-5);
}

.m-border-1-l {
    border-left: 1px solid var(--color-merkos-5);
}

.m-border-1-b {
    border-bottom: 1px solid var(--color-merkos-5);
}

.color-decoration {
    background: var(--color-merkos-5);
}

.fw-500 {
    font-weight: 500;
}

.text-1,
.text-1-hover:hover {
    color: var(--color-merkos-1);
}

.text-2 {
    color: var(--color-merkos-2)
}

.text-3 {
    color: var(--color-merkos-6);

}

.text-secondary-1 {
    color: var(--color-merkos-4);
}

.font-14 {
    font-size: 14px;
}

.font-18 {
    font-size: 18px;
}

.font-28 {
    font-size: 28px;
}

.text-secondary-2 {
    color: var(--color-merkos-2)
}

.m-m-1 {
    margin: 4px;
}

.m-m-2 {
    margin: 8px;
}

.m-m-3 {
    margin: 12px;
}

.m-m-4 {
    margin: 16px;
}

.m-m-5 {
    margin: 20px;
}

.m-m-6 {
    margin: 24px;
}

.m-m-7 {
    margin: 28px;
}

.m-m-8 {
    margin: 32px;
}

.m-m-e-1 {
    margin: 1em;
}

.m-m-e-2 {
    margin: 2em;
}

.m-m-e-3 {
    margin: 3em;
}

.m-m-e-4 {
    margin: 4em;
}

.m-mt-1 {
    margin-top: 4px;
}

.m-mt-2 {
    margin-top: 8px;
}

.m-mt-3 {
    margin-top: 12px;
}

.m-mt-4 {
    margin-top: 16px;
}

.m-mt-5 {
    margin-top: 20px;
}

.m-mt-6 {
    margin-top: 24px;
}

.m-mt-7 {
    margin-top: 28px;
}

.m-mt-8 {
    margin-top: 32px;
}

.m-my-1 {
    margin-top: 4px;
    margin-bottom: 4px;
}

.m-my-2 {
    margin-top: 8px;
    margin-bottom: 8px;
}

.m-my-3 {
    margin-top: 12px;
    margin-bottom: 12px;
}

.m-my-4 {
    margin-top: 16px;
    margin-bottom: 16px;
}

.m-my-5 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.m-my-6 {
    margin-top: 24px;
    margin-bottom: 24px;
}

.m-my-7 {
    margin-top: 28px;
    margin-bottom: 28px;
}

.m-my-8 {
    margin-top: 32px;
    margin-bottom: 32px;
}


// margin top em
.m-mt-e-1 {
    margin-top: 1em;
}

.m-mt-e-2 {
    margin-top: 2em;
}


.m-mb-1 {
    margin-bottom: 4px;
}

.m-mb-2 {
    margin-bottom: 8px;
}

.m-mb-3 {
    margin-bottom: 12px;
}

.m-mb-4 {
    margin-bottom: 16px;
}

.m-mb-5 {
    margin-bottom: 20px;
}

.m-mb-6 {
    margin-bottom: 24px;
}

.m-mb-7 {
    margin-bottom: 28px;
}

.m-mb-8 {
    margin-bottom: 32px;
}

.m-mb-9 {
    margin-bottom: 36px;
}

.m-mb-10 {
    margin-bottom: 40px;
}

.m-mb-11 {
    margin-bottom: 44px;
}

.m-mb-12 {
    margin-bottom: 48px;
}

// margin bottoom em
.m-mb-e-p5 {
    margin-bottom: 0.5em;
}

.m-mb-e-1 {
    margin-bottom: 1em;
}

.m-mb-e-2 {
    margin-bottom: 2em;
}

.m-mb-e-3 {
    margin-bottom: 3em;
}

.m-ms-1 {
    margin-left: 4px;
}

// em margins
.m-em-1 {
    margin: 0.25em;
}

.m-em-2 {
    margin: 0.5em;
}

.m-em-3 {
    margin: 0.75em;
}

.m-em-4 {
    margin: 1em;
}

.m-em-lr-1 {
    margin-left: 1em;
    margin-right: 1em;
}

.m-em-lr-2 {
    margin-left: 2em;
    margin-right: 2em;
}

.m-em-lr-3 {
    margin-left: 3em;
    margin-right: 3em;
}

.m-em-lr-4 {
    margin-left: 4em;
    margin-right: 4em;
}

// margin right with em
.m-em-mr-1 {
    margin-right: 0.25em;
}

.m-em-mr-2 {
    margin-right: 0.5em;
}

.m-em-mr-3 {
    margin-right: 0.75em;
}

.m-em-mr-4 {
    margin-right: 1em;
}


body {
    font-family: "Pluto Sans";
}

.m-ms-2 {
    margin-left: 8px;
}

.m-mx-8 {
    margin-right: 32px;
    margin-left: 32px;
}

.m-px-1 {
    padding-right: 4px;
    padding-left: 4px;
}

.m-px-2 {
    padding-right: 8px;
    padding-left: 8px;
}

.m-px-3 {
    padding-right: 12px;
    padding-left: 12px;
}

.m-px-4 {
    padding-right: 16px;
    padding-left: 16px;
}

.m-px-5 {
    padding-right: 20px;
    padding-left: 20px;
}

.m-px-6 {
    padding-right: 24px;
    padding-left: 24px;
}

.m-px-7 {
    padding-right: 28px;
    padding-left: 28px;
}

.m-px-8 {
    padding-right: 32px;
    padding-left: 32px;
}

.m-px-9 {
    padding-right: 36px;
    padding-left: 36px;
}

.m-px-10 {
    padding-right: 40px;
    padding-left: 40px;
}



.m-ms-3 {
    margin-left: 12px;
}

.m-ms-4 {
    margin-left: 16px;
}

.m-ms-5 {
    margin-left: 20px;
}

.m-ms-6 {
    margin-left: 24px;
}

.m-me-1 {
    margin-right: 4px;
}

.m-me-2 {
    margin-right: 8px;
}

.m-me-3 {
    margin-right: 12px;
}

.m-me-4 {
    margin-right: 16px;
}

.m-me-5 {
    margin-right: 20px;
}

// padding
.m-p-1 {
    padding: 4px;
}

.m-p-2 {
    padding: 8px;
}

.m-p-3 {
    padding: 12px;
}

.m-p-4 {
    padding: 16px;
}

.m-p-5 {
    padding: 20px;
}

.m-p-6 {
    padding: 24px;
}

.m-p-7 {
    padding: 28px;
}

.m-p-8 {
    padding: 32px;
}

.m-p-9 {
    padding: 36px;
}

.m-p-10 {
    padding: 40px;
}

.m-p-11 {
    padding: 44px;
}

.m-p-12 {
    padding: 48px;
}

// em padding
.m-p-e-p5 {
    padding: 0.5em;
}

.m-p-e-1 {
    padding: 1em;
}

.m-p-e-2 {
    padding: 2em;
}

.m-p-e-3 {
    padding: 3em;
}

.m-p-e-4 {
    padding: 4em;
}

.m-p-e-5 {
    padding: 5em;
}

.m-py-1 {
    padding-top: 4px;
    padding-bottom: 4px;
}

.m-py-2 {
    padding-top: 8px;
    padding-bottom: 8px;
}

.m-py-3 {
    padding-top: 12px;
    padding-bottom: 12px;
}

.m-py-4 {
    padding-top: 16px;
    padding-bottom: 16px;
}

.m-py-5 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.m-py-6 {
    padding-top: 24px;
    padding-bottom: 24px;
}

.m-py-7 {
    padding-top: 28px;
    padding-bottom: 28px;
}

.m-py-8 {
    padding-top: 32px;
    padding-bottom: 32px;
}

.m-pt-1 {
    padding-top: 4px;
}

.m-pt-2 {
    padding-top: 8px;
}

.m-pt-3 {
    padding-top: 12px;
}

.m-pt-4 {
    padding-top: 16px;
}

.m-pt-5 {
    padding-top: 20px;
}

.m-pt-6 {
    padding-top: 24px;
}

.m-pt-7 {
    padding-top: 28px;
}

.m-pt-8 {
    padding-top: 32px;
}

.m-pt-9 {
    padding-top: 36px;
}

.m-pt-10 {
    padding-top: 40px;
}

.m-pb-1 {
    padding-bottom: 4px;
}

.m-pb-2 {
    padding-bottom: 8px;
}

.m-pb-3 {
    padding-bottom: 12px;
}

.m-pb-4 {
    padding-bottom: 16px;
}

.m-pb-5 {
    padding-bottom: 20px;
}

// padding bottom em
.m-pb-e-1 {
    padding-bottom: 1em;
}

.m-pb-e-2 {
    padding-bottom: 2em;
}

.m-ps-1 {
    padding-left: 4px;
}

.m-ps-2 {
    padding-left: 8px;
}

.m-ps-3 {
    padding-left: 12px;
}

.m-ps-4 {
    padding-left: 16px;
}

.m-ps-5 {
    padding-left: 20px;
}

.m-ps-6 {
    padding-left: 24px;
}

.m-pe-1 {
    padding-right: 4px;
}

.m-pe-2 {
    padding-right: 8px;
}

.m-pe-3 {
    padding-right: 12px;
}

.m-pe-4 {
    padding-right: 16px;
}

.m-pe-5 {
    padding-right: 20px;
}

@media screen and (max-width: 480px) {
    .m-sm-p-1 {
        padding: 4px !important;
    }

    .m-sm-px-1 {
        padding-right: 4px !important;
        padding-left: 4px !important;
    }

    .m-sm-p-0 {
        padding: 0px !important;
    }

    .m-sm-p-2 {
        padding: 8px !important;
    }

    .m-sm-p-3 {
        padding: 12px !important;
    }

    .m-px-sm-3 {
        padding-right: 12px !important;
        padding-left: 12px !important;
    }

}

.ui-element:hover {
    outline: 2px solid var(--color-merkos-4);

}

.ui-element.active {
    outline: 2px solid var(--color-merkos-4) !important;

}

.ui-element .spacer:hover {
    background-color: #e5e5f7;
    opacity: 0.2;
    background-size: 20px 20px;
    background-image: repeating-linear-gradient(0deg, var(--color-merkos-4), var(--color-merkos-4) 1px, #e5e5f7 1px, #e5e5f7);
}

.editor-val-input {
    border: 0 solid #87899b;
    border-width: 0 0 1px 0;
}

.hgt-30 {
    height: 30%;
}

.hgt-40 {
    height: 40%;
}

.hgt-60 {
    height: 60%;
}

.hgt-70 {
    height: 70%;
}

.hgt-80 {
    height: 80%;
}

.w-wfa {
    width: -webkit-fill-available;
}

.clndr-event {
    font-size: 12px;
    padding: 2px;
    color: white;
    text-align: left;
    border-radius: 4px;
    font-family: inherit;
}

.mw-2 {
    max-width: 2rem;
}


.active-loader:after {
    content: '';
    display: block;
    margin: auto;
    position: absolute;
    height: 1px;
    transition: width .5s ease, background-color .2s ease;
    animation: .6s infinite alternate activeLoaderEffect;
    width: 100%;

}

@keyframes activeLoaderEffect {
    from {
        transform: scaleX(70%);
        background: #202789;
    }

    to {
        transform: scaleX(100%);
        background: transparent;

    }
}

.f-fl {
    float: left;
}

.f-fr {
    float: right;
}

.fl-dir-col {
    display: flex;
    flex-direction: column;
}

.fl-dir-row {
    display: flex;
    flex-direction: row;
}

.traget-trans-pre {
    height: 0%;
    transition: all 1s ease-in-out;
}

.target-trans-post {
    height: 100%;

}

.hover-dialog {
    background: var(--color-merkos-3) white;
    width: max-content;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid var(--color-merkos-5);
    color: --var(color-merkos-1);
    font-size: 13px;
    min-height: 30px;
    letter-spacing: 1px;
    line-height: 30px;
    position: absolute;
    text-align: center;
    // text-transform: uppercase;
    // top: -80px;
    border-radius: 4px;
    transform: translateY(-105%);
    
    // left: -30px;
    // display: none;
    padding: 4px 20px;
    width: 350px;
    max-width: 80vw;

}

.hover-dialog:after {
    content: '';
    position: absolute;
    bottom: -7px;
    width: 10px;
    height: 10px;
    border-bottom: 1px solid var(--color-merkos-5);
    border-right: 1px solid var(--color-merkos-5);
    background: var(--color-merkos-3);
    left: 30px;
    margin-left: -10px;
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 100;
}

.hover-dialog-container {
    position: relative;
    cursor: pointer;
}

.hover-dialog-container:hover .hover-dialog {
    display: block;
}