$primary: #202789; // use var(--color-merkos-primary)

$color-white: #ffffff;
$color-black: #000000;
$color-1: #171c6233;
$color-2: #2f59cc;
$color-3: #a2a4c0; // use var(--color-merkos-6)

$border-color: #e5e5ef;

$ck-color-base-focus: rgba(32, 39, 137, 0.25);
$gray-form: #a2a4c0; // use var(--color-merkos-6)

$color-text: #495057;
$text-2: #676b98; // use var(--color-merkos-2)

$primary-color-btn-outline: #676b98;

:root {
  --color-merkos-primary: #202789;
  --color-merkos-1: #171C62; //.text-1
  --color-merkos-2: #676B98;
  --color-merkos-3: #FBFBFE; //.bg-3 
  --color-merkos-4: #2F80ED;
  --color-merkos-5: #E9E9F3;// .bg-secondary-1, .bg-decoration, .hover-bg-secondary-1:hover
  --color-merkos-6: #A2A4C0;
  
  --decoration-color: #E9E9F3; // use var(--color-merkos-5)
  --text-2: #676b98; // use var(--color-merkos-2)
}

.ms-text-primary {
  color: $primary;
}

.ms-text-2 {
  color: var(--color-merkos-2);
}

.ms-background-2-hover:hover {
  background: #f6f5f4;
}

.ms-background-2 {
  background: #f6f5f4;
}