.post {
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid $border-color;
  box-shadow: 1px 1px 3px rgb(0 0 0 / 10%);
  border-radius: 15px;
}

.post .author-date {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.post .author {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: $color-2;
}

.post .title {
  color: var(--color-merkos-primary);
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 700;
}

.post .discription {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: $color-black;
}

.post .actionables {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  border-top: 1px solid $border-color;
}

.post .actionables .btn {
  color: var(--color-merkos-primary);
  font-weight: 650;
  padding: 5px 15px;
  border: 1px solid $border-color;
  border-radius: 15px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.discussions-postview {
  .postview-header {
    width: 100%;
    color: var(--color-merkos-primary);
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: 700;

    background: #e2e2e2;
    border-radius: 15px 15px 0px 0px;
    padding: 10px 25px 10px 25px;

    .fa-arrow-left {
      margin-right: 1.5rem;
    }
  }

  .postview-body {
    padding: 10px 25px 10px 25px;
    height: 500px;
    overflow-y: auto;
    background-color: #efeff0;

    .chat {
      position: relative;
      margin-bottom: 10px;
      padding: 15px;
      background-color: $color-white;
      box-shadow: 0.5px 0.5px 1px rgb(0 0 0 / 10%);
    }

    .chat-left {
      border-radius: 0px 15px 15px 15px;
    }

    .chat-right {
      border-radius: 15px 0px 15px 15px;
    }

    /*right arrow*/
    .chat-right-arraw:after {
      content: "";
      position: absolute;
      right: -8px;
      top: 0;
      border-bottom: 20px solid transparent;
      border-left: 10px solid $color-white;
    }

    /*left arrow*/
    .chat-left-arraw:before {
      content: "";
      position: absolute;
      left: -8px;
      top: 0;
      border-bottom: 20px solid transparent;
      border-right: 10px solid $color-white;
    }

    .chat .chat-header {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;

      button {
        background: none;
        border: none;
      }
    }

    .chat .chat-header .username {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: $color-2;
    }

    .chat .chat-header .date {
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      text-align: right;
      color: $color-3;
    }
  }

  .postview-footer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 10px;

    background: #e2e2e2;
    border: 1px solid #e2e3f0;
    border-radius: 0px 0px 15px 15px;
    padding: 10px 25px 10px 25px;

    input {
      padding: 10px;
      background: #fbfbfe;
      box-shadow: 0.5px 0.5px 1px rgb(0 0 0 / 10%);
      border: none;
      border-radius: 15px;

      &:focus {
        border-color: var(--color-merkos-primary);
        box-shadow: 0 0 0 0.2rem rgb(32 39 137 / 25%);
      }
    }

    .btn {
      background-color: var(--color-merkos-primary);
      color: $color-white;
      border-radius: 15px;
      font-size: 20px;
      height: 40px;
      width: 40px;
      box-shadow: 0.5px 0.5px 1px rgb(0 0 0 / 10%);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .bg-white {
      background-color: $color-white;
      color: var(--color-merkos-primary);
    }
  }
}
